import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  // Basic URL for this endpoint
  private apiUrl = `${environment.apiUrl}`

  constructor(
    private http: HttpClient,
  ) { }

  registerCompany(registerData) {
    return(this.http.post(`${this.apiUrl}/onboard`, registerData)); 
  }

}
