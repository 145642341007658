import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class JobGroupService {

  // Basic URL for this endpoint
  private apiUrl = `${environment.apiUrl}/jobgroup`

  constructor(
    private http: HttpClient,
  ) { }

  create(jobgroupData) {
    return(this.http.post(`${this.apiUrl}`, jobgroupData))
  }

  read(mode?) {
    if(mode == 'active') {
      var status = "0"
    } else {
      status = "0,1"
    }
    return(this.http.get<any[]>(`${this.apiUrl}/?status=`+status)); 
  }

  read_single(jobgroupID) {
    return(this.http.get(`${this.apiUrl}/read_single.php?jobgroupID=${jobgroupID}`))
  }

  getUsers(forbidden_users) {
    if(forbidden_users) {
      return(this.http.get<any[]>(`${environment.apiUrl}/user/?id=`+forbidden_users))
    } else {
      return(this.http.get<any[]>(`${environment.apiUrl}/user/all`))
    }
  }

  storeJobgroups(data) {
    return(this.http.post(`${this.apiUrl}/store.php`, data))
  }

  storeUsers(userData) {
    return(this.http.patch(`${this.apiUrl}`, userData))
  }

  delete(jobgroupID) {
    var data = {
      jobgroupID: jobgroupID
    }
    return(this.http.put(`${this.apiUrl}/`+jobgroupID, data))
  }

  hideJobgroup(jobgroupID) {
    return(this.http.post(`${this.apiUrl}/hide.php`, {
      jobgroupID: jobgroupID
    }))
  }

  showJobgroup(jobgroupID) {
    return(this.http.post(`${this.apiUrl}/show.php`, {
      jobgroupID: jobgroupID
    }))
  }

}
