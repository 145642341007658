import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './services/app.service';

import { AuthGuardService as AuthGuard } from './services/authGuard';
import { ToastService } from './services/toast.service';

import { ChangeDetectorRef,AfterContentChecked} from '@angular/core'
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterContentChecked {
  title = 'asgood';
  isLogedIn: boolean;
  loading: boolean = false

  constructor(
    private AuthGuard: AuthGuard,
    private appService: AppService,
    private router: Router,
    public toastService: ToastService,
    private cdRef : ChangeDetectorRef,
  ) {


  }

  ngOnInit() {

    /*this.appService.getConfig().subscribe(data => {

      if(data['data']['maintenance'] == 1) {
        this.router.navigateByUrl('/maintenance')
      }

    })*/

  }

  ngAfterContentChecked() : void {
    this.cdRef.detectChanges();
  }


}
