<div class="limiter">
    <div class="container-login100" style="background-image: url('/assets/images/login.jpg');">
        <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54" >

            <h1 style="text-align:center;"> <img src="/assets/images/logo.png" style="height:50px;max-height:100px;"/> Passwort zurücksetzen</h1>

            <form [formGroup]="forgotPasswordForm" novalidate autocomplete="off">

                <div *ngIf="responseService.returnObject">
                      <div [ngStyle] ="{'color': responseService.returnColor}" style="text-align:center;" [innerHTML]="responseService.returnObject"></div>
                </div>

                <div style="text-align:center;" class="form-group row">

                    <div class="col-md-12">
                        <mat-form-field style="width:40%;" class="example-full-width">
                            <input type="email" matInput placeholder="Email Adresse eingeben" formControlName="userEmail"
                            autocomplete="off">
                        </mat-form-field>
                        <mat-error *ngIf="forgotPasswordForm.get('userEmail').hasError('required') && forgotPasswordForm.get('userEmail').touched">Bitte eine Email Adresse eingeben</mat-error>
                        <mat-error *ngIf="forgotPasswordForm.get('userEmail').hasError('email') && forgotPasswordForm.get('userEmail').touched">Bitte eine gültige Email Adresse eingeben</mat-error>
                    </div>

                    <div class="col-md-12">
                        <button mat-raised-button style="background: #2CA5D8;border-color: #2CA5D8;color:white;" [disabled]="!forgotPasswordForm.valid" (click)="submit()">Passwort zurücksetzen</button>  
                    </div>

                </div>

            </form>

        </div>
    </div>
</div>