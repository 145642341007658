<div class="limiter">
    <div class="container-login100" style="background-image: url('/assets/images/login.jpg');">
        <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54" >

            <h1 style="text-align:center;"> <img src="/assets/images/logo.png" style="height:50px;max-height:100px;"/> AS GOoD - {{ companyName }}</h1>

            <form [formGroup]="onboardingForm" novalidate autocomplete="off">

                <mat-horizontal-stepper linear #stepper>

                    <mat-step>

                        <ng-template matStepLabel>Willkommen</ng-template>

                        <div style="text-align:center;">

                            <h4>Willkommen, {{ userSurname }} {{ userName }}</h4>
            
                            <p>Damit du AS GOoD für deine Firma nutzen kannst musst du noch einge wenige Schritte ausführen.</p>
            
                        </div>

                        <div style="text-align:center;margin-top:15px;">
                            <button mat-raised-button color="primary" matStepperNext>Los gehts!</button>
                        </div>

                    </mat-step>

                    <mat-step [stepControl]="onboardingForm.get('userAGB')" formGroupName="userAGB">

                        <ng-template matStepLabel>AGB Akzeptieren</ng-template>

                        <div style="max-height: 300px;overflow-y: scroll;">
                            <h2>AS GOoD – Allgemeine Geschäftsbedingungen (AGB)</h2><br><br>
            
                            Stand: 19.01.2020<br><br>
            
                            AS GOoD ist eine Plattform, welche schweizerischen Unternehmen sowie anderen Nutzerinnen und Nutzern erleichtert, die Arbeitssicherheit und den Gesundheitsschutz zu gewährleisten (nachfolgend die «Plattform»). Anbieterin der Plattform ist die Enderle Beratung GmbH mit Sitz in Winterthur (nachfolgend die «Anbieterin»).
                            <br><br>
                            AS GOoD steht für ArbeitsSicherheit und Gesundheitsschutz Organisieren und Dokumentieren. Mit der Plattform können Unternehmen ihre Tätigkeiten betreffend Arbeitssicherheit und Gesundheitsschutz, die sie in ihrem Betrieb durchführen, dokumentieren und systematisieren. Mit der Plattformen können Unternehmen insbesondere ein Sicherheitssystem gemäss EKAS-Richtlinie 6508 umsetzen, eine Gefahrenermittlung durchführen und die getroffenen Massnahmen dokumentieren.
                            <br><br>
                            Diese Allgemeinen Geschäftsbedingungen (AGB) regeln die Rechte und Pflichten der Anbieterin sowie der Nutzerinnen und Nutzer der Plattform (nachfolgend gemeinsam die «Nutzer»). Für einzelne oder zusätzliche Funktionen und Leistungen können ergänzende oder weitere Bedingungen und sonstige rechtliche Dokumente gelten. Informationen zum Datenschutz finden sich in der Datenschutzerklärung.
                            <br><br>
                            1.	Funktionen und Leistungen von AS GOoD
                            <br><br>
                            1.1	Die Anbieterin betreibt die Plattform für die einzelnen Nutzer als Software-as-a-Service (SaaS)-Angebot. Die Anbieterin stellt den Nutzern den Zugang zur jeweils aktuellen Version der Plattform zur Verfügung.
                            <br><br>
                            1.2	Die Anbieterin ermöglicht den Nutzern, offizielle ASA-Checklisten und -Dokumente wie insbesondere Checklisten und Dokumente der EKAS, des SECO und der SUVA zu verwenden, womit für die betreffenden Themen der ASA-Beizug gewährleistet ist. In einem Betrieb gilt das erforderliche Fachwissen als vorhanden, wenn «von ASA entwickelte Unterlagen, beispielsweise Checklisten» umgesetzt werden (EKAS-Richtlinie 6508, Anhang 4 «Erforderliches Fachwissen»).
                            <br><br>
                            1.3	Die einzelnen Funktionen und Leistungen der Plattform sowie die damit verbundenen Kosten richten sich nach den vertraglichen Vereinbarungen zwischen der Anbieterin und einzelnen Nutzern sowie nach den Bedingungen, welche die Anbieterin im Rahmen der Plattform, auf ihrer Website oder anderweitig veröffentlicht hat. Es gibt keinen Anspruch, die Plattform nutzen zu dürfen.
                            <br><br>
                            1.4	Die Anbieterin betreibt die Infrastruktur für die Plattform, speichert die Daten der Nutzer im Zusammenhang mit der Plattform und leistet den Nutzern angemessene Unterstützung bei der Nutzung der Plattform. Die Anbieterin ist berechtigt, alle oder einzelne Leistungen im Zusammenhang mit der Plattform durch beauftragte oder anderweitig beigezogene Dritte erbringen zu lassen.
                            <br><br>
                            1.5	Die Anbieterin ist berechtigt, die Plattform insgesamt oder in Bezug auf einzelne Funktionen und Leistungen anzupassen und weiterzuentwickeln. Die Anbieterin informiert die Nutzer in geeigneter Form über massgebliche Anpassungen.
                            <br><br>
                            1.6	Die Anbieterin ist berechtigt, den Nutzern Mitteilungen im Zusammenhang mit der Plattform per E-Mail, Instant Messaging und über sonstige Kommunikationskanäle zu senden. Solche Mitteilungen können auch Werbung für Funktionen und Leistungen der Plattform enthalten. Die Nutzer können dem Empfang von Mitteilungen, die Werbung enthalten, jederzeit widersprechen («Opt-out»).
                            <br><br>
                            2.	Nutzung von AS GOoD
                            <br><br>
                            2.1	Die Anbieterin räumt den Nutzern während der jeweiligen Vertragsdauer ein nicht ausschliessliches, nicht übertragbares und nicht unterlizenzierbares Nutzungsrecht für die Plattform und sämtliche vereinbarten Funktionen, Inhalte und sonstigen Leistungen im Zusammenhang mit der Plattform für ausschliesslich eigene gewerbliche beziehungsweise unternehmerische Zwecke ein.
                            <br><br>
                            2.2	Das Nutzungsrecht ist auf die rechtskonforme und vereinbarungsgemässe Nutzung der Plattform durch die einzelnen Nutzer beschränkt. Die Nutzung der Plattform erfolgt in eigener Verantwortung der Nutzer. Bei Gefährdungen im Betrieb, welche nicht in ASA-Checklisten und -Dokumenten aufbereitet sind, muss der ASA-Beizug zusätzlich gewährleistet werden: «Der Arbeitgeber zieht Spezialisten der Arbeitssicherheit bei, wenn in seinem Betrieb besondere Gefährdungen […] auftreten und wenn in seinem Betrieb das erforderliche Fachwissen zur Gewährleistung der Arbeitssicherheit und des Gesundheitsschutzes nicht vorhanden ist.» (EKAS-Richtlinie 6508, Ziffer 2 (Beizug von Arbeitsärzten und anderen Spezialisten der Arbeitssicherheit).
                            <br><br>
                            2.3	Die Anbieterin stellt den Nutzern individuelle Nutzerkonten zur Verfügung oder ermöglicht bestimmten Nutzern, individuelle Nutzerkonten für Nutzer im gleichen Unternehmen selbst zu erstellen (nachfolgend die «Hauptnutzer»). Alle Nutzer sind verpflichtet, mit angemessenen technischen und organisatorischen Massnahmen eine missbräuchliche Nutzung zu verhindern und den Zugang zu ihren Nutzerkonten zu schützen. Hauptnutzer sind verantwortlich für die vereinbarungsgemässe Nutzung der Plattform im Rahmen von selbst erstellten individuellen Nutzerkonten.
                            <br><br>
                            2.4	Die Anbieterin speichert die Daten der Nutzer – einschliesslich Dokumente mit Sicherheits-Checklisten – während der jeweiligen Vertragsdauer. Die Daten gehören zum Rechtsbereich der einzelnen Nutzer, die ausschliesslich für die Speicherung und sonstige Bearbeitung solcher Daten verantwortlich sind. Die Datensicherung (Backup) obliegt den einzelnen Nutzern. Bei der Bearbeitung von Personendaten sind die Nutzer zu einer strikten Einhaltung des anwendbaren Datenschutzrechts verpflichtet.
                            <br><br>
                            2.5	Die Anbieterin behandelt sämtliche Informationen, die sie von Nutzern im Rahmen der Nutzung der Plattform erfährt, vertraulich und unterlässt die Weitergabe solcher Informationen an unberechtigte Dritte. Die Vertraulichkeitspflicht bleibt über die Vertragsdauer hinaus bestehen. Die Anbieterin trifft angemessene Massnahmen gegen Datenverlust und zur Gewährleistung der Datensicherheit. Sofern und soweit die Anbieterin nicht gesetzlich oder aus anderen Gründen zur Aufbewahrung von Daten der Nutzer verpflichtet ist, ist die Anbieterin berechtigt, solche Daten nach Vertragsende zu löschen.
                            <br><br>
                            2.6	Jegliche Nutzung der Plattform in rechtswidriger Art und Weise oder zu rechtswidrigen Zwecken ist untersagt. Es obliegt den Nutzern insbesondere zu gewährleisten, dass das anwendbare Datenschutz- und Urheberrecht eingehalten wird sowie keine Rechte Dritter verletzt werden.
                            <br><br>
                            2.7	Die Nutzer halten die Anbieterin für sämtliche Aufwendungen und Kosten im Zusammenhang mit jeglicher Haftung schadlos, die durch eine rechtswidrige Nutzung der Plattform entstehen. Die Nutzer stellen die Anbieterin weiter von sämtlichen Ansprüchen anderer Nutzer sowie allfälliger Dritter frei, sofern und soweit solche Ansprüche im Zusammenhang mit ihrer Nutzung der Plattform stehen.
                            <br><br>
                            2.8	Die Anbieterin ist jederzeit berechtigt, bei einer Verletzung dieser Nutzungsbedingungen oder von sonstigen vertraglichen Vereinbarungen sowie bei einem Verdacht auf eine missbräuchliche Nutzung, einzelnen Nutzern den Zugang zur Plattform unverzüglich zu sperren. Die Anbieterin kann betroffene Nutzer in solchen Fällen im eigenen Ermessen abmahnen, ist aber nicht dazu verpflichtet.
                            <br><br>
                            3.	Kosten und Zahlungsbedingungen
                            <br><br>
            
                            3.1	Die Nutzer verpflichten sich, jederzeit sämtlichen finanziellen Verpflichtungen gegenüber der Anbieterin fristgerecht und vollumfänglich nachzukommen. Die Anbieterin ist nicht verpflichtet, geleistete Zahlungen zurückzuerstatten. Sämtliche anfallenden Kosten sind per sofort geschuldet, sofern die Anbieterin keine andere Zahlungsfrist nennt. Die Anbieterin ist berechtigt, die Nutzung der Plattform von Akonto- oder Vorauszahlungen abhängig zu machen.
                            <br><br>
                            3.2	Die Nutzer geraten bei nicht fristgerechter Bezahlung ohne Zahlungserinnerung automatisch in Verzug. Die Anbieterin ist berechtigt, säumigen Nutzern den Zugang zur Plattform zu sperren. Die Anbieterin ist zustimmungsfrei berechtigt, Forderungen gegenüber Nutzern an Dritte abzutreten. Die Verrechnung von Forderungen durch Nutzer ist ohne die schriftliche Zustimmung der Anbieterin ausgeschlossen.
                            <br><br>
                            4.	Gewährleistung und Haftung
                            <br><br>
                            4.1	Die Anbieterin erbringt ihre Leistungen fachgerecht und sorgfältig. Die Anbieterin bemüht sich, dass der vertraglich vereinbarte Funktions- und Leistungsumfang der Plattform während der jeweiligen Vertragslaufzeit genutzt werden kann. Bei Mängeln, die Nutzer melden, bemüht sich die Anbieterin, solche Mängel innert angemessener Frist und mit angemessenen Massnahmen zu beheben.
                            <br><br>
                            4.2	Die Anbieterin ist berechtigt, den Zugang zur Plattform für Wartungsarbeiten zu unterbrechen. Die Anbieterin informiert die Nutzer nach Möglichkeit über geplante Wartungsfenster.
                            <br><br>
                            4.3	Die Anbieterin bemüht sich um eine hohe Verfügbarkeit und Zuverlässigkeit der Plattform, kann aber nicht gewährleisten, dass die Plattform fehlerfrei ist oder dauerhaft und jederzeit mit vollem Funktions- und Leistungsumfang verfügbar ist. Für Inhalte, die von Dritten oder von anderen Nutzern stammen, insbesondere für Sicherheits-Checklisten von Dritten, kann die Anbieterin keinerlei Gewähr übernehmen.
                            <br><br>
                            4.4	Jegliche Haftung der Anbieterin oder ihrer Hilfspersonen für direkte Sach- und Vermögensschäden sowie andere oder weitergehende Ansprüche und Schäden, insbesondere betreffend indirekte, mittelbare oder Folgeschäden, entgangener Gewinn, fehlerhafte oder unvollständige Informationen, entgangene Nutzung sowie Betriebs- oder Verdienstausfälle, ist ausdrücklich und vollumfänglich ausgeschlossen.
                            <br><br>
                            4.5	Die Beschränkung der Haftung gemäss diesen Allgemeinen Geschäftsbedingungen gilt unabhängig vom jeweiligen Rechtsgrund. Eine allenfalls weitergehende zwingende Haftung, insbesondere für grobe Fahrlässigkeit oder für Vorsatz sowie gemäss allenfalls anwendbarem Datenschutzrecht, bleibt vorbehalten.
                            <br><br>
                            5.	Vertragsdauer
                            <br><br>
                            5.1	Vertragsbeginn und Vertragsdauer richten sich nach den entsprechenden vertraglichen Vereinbarungen. Ansonsten beginnt der Vertrag mit dem gewährten Zugang zur Plattform und die Vertragslaufzeit ist unbeschränkt. Mit Vertragsende wird der Zugang zur Plattform gesperrt.
                            <br><br>
                            5.2	Der Vertrag kann sowohl von der Anbieterin als auch von Nutzern ordentlich mit einer Kündigungsfrist von zwei Monaten auf Monatsende gekündigt werden, sofern keine andere Kündigungsfrist vertraglich vereinbart wurde. Die Kündigung muss schriftlich oder in einer Form, die den Nachweis durch Text ermöglicht, erfolgen.
                            <br><br>
                            5.3	Der Vertrag kann von den Nutzern ausserordentlich mit einer Kündigungsfrist von 14 Tagen auf Monatsende aus wichtigem Grund gekündigt werden, wenn die Anbieterin den wichtigen Grund trotz Abmahnung nicht innerhalb einer angemessenen Frist von mindestens 30 Tagen behoben hat. Abmahnung und Kündigung müssen schriftlich erfolgen.
                            <br><br>
                            6.	Schlussbestimmungen
                            <br><br>
                            6.1	Der automatisierte Zugriff auf die Plattform, beispielsweise mit Bots, Skripten oder vergleichbaren Mitteln, ist untersagt. Die Anbieterin kann Ausnahmen wie beispielsweise die Nutzung von Programmierschnittstellen vorsehen.
                            <br><br>
                            6.2	Sollten einzelne Bestimmungen dieser Allgemeinen Geschäftsbedingungen unwirksam oder nichtig sein, so wird die Geltung der übrigen Bestimmungen dieser Allgemeinen Geschäftsbedingungen davon nicht berührt. Eine unwirksame oder nichtige Bestimmung ist durch eine rechtlich zulässige Bestimmung zu ersetzen, die dem ursprünglichen Zweck wirtschaftlich am nächsten kommt.
                            <br><br>
                            6.3	Die Anbieterin ist jederzeit berechtigt, diese Allgemeinen Geschäftsbedingungen ohne Angabe von Gründen anzupassen. Die Nutzer werden in geeigneter Form über massgebliche Änderungen dieser Allgemeinen Geschäftsbedingungen informiert.
                            <br><br>
                            6.4	Diese Allgemeinen Geschäftsbedingungen unterstehen ausschliesslich schweizerischem Recht mit ausschliesslichem Erfüllungsort und Gerichtsstand am Sitz der Anbieterin.
            
                        </div>
            
                        <mat-checkbox matInput formControlName="userAcceptedAGB" color="primary">
                            Ja, ich habe die allgemeinen Geschäftsbedingungen (AGBs) <br> gelesen und akzeptiere diese. 
                        </mat-checkbox>

                        <div style="text-align:center;margin-top:15px;">
                            <button mat-raised-button matStepperPrevious style="margin-right:10px;">Zurück</button>
                            <button mat-raised-button color="primary" matStepperNext [disabled]="!onboardingForm.get('userAGB').valid">Weiter</button>
                        </div>

                    </mat-step>

                    <mat-step [stepControl]="onboardingForm.get('userDSE')" formGroupName="userDSE">

                        <ng-template matStepLabel>DSE Akzeptieren</ng-template>

                        <div style="max-height: 300px;overflow-y: scroll;">
                            <h1 id="top">Datenschutzerklärung</h1>

                            <p>Mit dieser <strong>Datenschutzerklärung</strong> informieren wir, welche Personendaten wir im Zusammenhang mit unseren <strong>Aktivitäten und Tätigkeiten</strong> einschliesslich unserer <strong>AS GOoD-Plattform (asgood.ch) </strong> bearbeiten. Wir informieren insbesondere, wofür, wie und wo wir welche Personendaten bearbeiten. Wir informieren ausserdem über die Rechte von Personen, deren Daten wir bearbeiten.</p>
                            
                            <p>Für einzelne oder zusätzliche Aktivitäten und Tätigkeiten können weitere Datenschutzerklärungen sowie sonstige rechtliche Dokumente wie Allgemeine Geschäftsbedingungen (AGB), Nutzungsbedingungen oder Teilnahmebedingungen gelten.</p>
                            
                            <h2 id="kontaktadressen">1. Kontaktadressen</h2>
                            
                            <p>Verantwortung für die Bearbeitung von Personendaten:</p>
                            
                            <p><strong id="verantwortlicher">Enderle Beratung GmbH</strong><br />
                            Jonas-Furrer-Strasse 83<br />
                            8400 Winterthur</p>
                            
                            <p><a href="mailto:info@enderle-beratung-gmbh.ch">info@enderle-beratung-gmbh.ch</a></p>
                            
                            <p>Wir weisen darauf hin, wenn es im Einzelfall andere Verantwortliche für die Bearbeitung von Personendaten gibt.</p>
                            
                            <h2 id="begriffe-rechtsgrundlagen">2. Begriffe und Rechtsgrundlagen</h2>
                            
                            <h3 id="begriffe">2.1 Begriffe</h3>
                            
                            <p><strong>Personendaten</strong> sind <em>alle</em> Angaben, die sich auf eine bestimmte oder bestimmbare natürliche Person beziehen. Eine <strong>betroffene Person</strong> ist eine Person, über die wir Personendaten bearbeiten.</p>
                            
                            <p><strong>Bearbeiten</strong> umfasst <em>jeden</em> Umgang mit Personendaten, <em>unabhängig</em> von den angewandten Mitteln und Verfahren, beispielsweise das Abfragen, Abgleichen, Anpassen, Archivieren, Aufbewahren, Auslesen, Bekanntgeben, Beschaffen, Erfassen, Erheben, Löschen, Offenlegen, Ordnen, Organisieren, Speichern, Verändern, Verbreiten, Verknüpfen, Vernichten und Verwenden von Personendaten.</p>
                            
                            <h3 id="rechtsgrundlagen">2.2 Rechtsgrundlagen</h3>
                            
                            <p>Wir bearbeiten Personendaten im Einklang mit dem schweizerischen Datenschutzrecht wie insbesondere dem <a href="https://www.fedlex.admin.ch/eli/oc/2022/491/de" rel="nofollow noopener noreferrer" target="_blank" id="15192">Bundesgesetz über den Datenschutz</a> (Datenschutzgesetz, DSG) und der <a href="https://www.fedlex.admin.ch/eli/oc/2022/568/de" rel="nofollow noopener" target="_blank">Verordnung über den Datenschutz</a> (Datenschutzverordnung, DSV).</p>
                            
                            <h2 id="art-umfang-zweck">3. Art, Umfang und Zweck</h2>
                            
                            <p>Wir bearbeiten jene Personendaten, die <em>erforderlich</em> sind, um unsere Aktivitäten und Tätigkeiten dauerhaft, nutzerfreundlich, sicher und zuverlässig ausüben zu können. Solche Personendaten können insbesondere in die Kategorien von Bestandes- und Kontaktdaten, Browser- und Gerätedaten, Inhaltsdaten, Meta- bzw. Randdaten und Nutzungsdaten, Standortdaten, Verkaufsdaten sowie Vertrags- und Zahlungsdaten fallen.</p>
                            
                            <p>Wir bearbeiten Personendaten während jener <em>Dauer</em>, die für den jeweiligen Zweck bzw. die jeweiligen Zwecke oder gesetzlich erforderlich ist. Personendaten, deren Bearbeitung nicht mehr erforderlich ist, werden anonymisiert oder gelöscht.</p>
                            
                            <p>Wir können Personendaten <em>durch Dritte</em> bearbeiten lassen. Wir können Personendaten gemeinsam mit Dritten bearbeiten oder an Dritte übermitteln. Bei solchen Dritten handelt es sich insbesondere um spezialisierte Anbieter, deren Leistungen wir in Anspruch nehmen. Wir gewährleisten auch bei solchen Dritten den Datenschutz.</p>
                            
                            <p>Wir bearbeiten Personendaten <em>grundsätzlich</em> nur mit Einwilligung der betroffenen Personen. Sofern und soweit die Bearbeitung aus anderen rechtlichen Gründen zulässig ist, können wir darauf verzichten, eine Einwilligung einzuholen. Wir können Personendaten beispielsweise ohne Einwilligung bearbeiten, um einen Vertrag zu erfüllen, um rechtlichen Verpflichtungen nachzukommen oder um überwiegende Interessen zu wahren.</p>
                            
                            <p>In diesem Rahmen bearbeiten wir insbesondere Angaben, die eine betroffene Person bei der Kontaktaufnahme&nbsp;– beispielsweise per Briefpost, E-Mail, Instant Messaging, Kontaktformular, Social Media oder Telefon&nbsp;– oder bei der Registrierung für ein Nutzerkonto <em>freiwillig</em> an uns übermittelt. Wir können solche Angaben beispielsweise in einem Adressbuch, in einem Customer-Relationship-Management-System (CRM-System) oder mit vergleichbaren Hilfsmitteln speichern. Wenn wir Daten über andere Personen übermittelt erhalten, sind die übermittelnden Personen verpflichtet, den Datenschutz gegenüber diesen Personen zu gewährleisten sowie die Richtigkeit dieser Personendaten sicherzustellen.</p>
                            
                            <p>Wir bearbeiten ausserdem Personendaten, die wir von Dritten erhalten, aus öffentlich zugänglichen Quellen beschaffen oder bei der Ausübung unserer Aktivitäten und Tätigkeiten erheben, sofern und soweit eine solche Bearbeitung aus rechtlichen Gründen zulässig ist.</p>
                            
                            <h2 id="ausland">4. Personendaten im Ausland</h2>
                            
                            <p>Wir bearbeiten Personendaten <em>grundsätzlich</em> in der Schweiz. Wir können Personendaten aber auch in andere Staaten bekanntgeben bzw. exportieren, insbesondere um sie dort zu bearbeiten oder bearbeiten zu lassen.</p>
                            
                            <p>Wir können Personendaten in alle <a href="https://de.wikipedia.org/wiki/Liste_der_Staaten_der_Erde#Tabelle" rel="nofollow noopener noreferrer" target="_blank">Staaten und Territorien auf der Erde</a> sowie anderswo im <a href="https://www.datenschutzpartner.ch/2022/04/23/datenschutzerklaerung-daten-export-universum-mond/" rel="nofollow noopener noreferrer" target="_blank">Universum</a> bekanntgeben, sofern das dortige Recht gemäss <a href="https://steigerlegal.ch/2022/09/10/ndsg-schweiz-staatenliste/" rel="nofollow noopener noreferrer" target="_blank">Beschluss des Schweizerischen Bundesrates</a> einen angemessenen Datenschutz gewährleistet.</p>
                            
                            <p>Wir können Personendaten in Staaten, deren Recht keinen angemessenen Datenschutz gewährleistet, bekanntgeben, sofern aus anderen Gründen ein geeigneter Datenschutz gewährleistet ist. Ein geeigneter Datenschutz kann beispielsweise durch entsprechende vertragliche Vereinbarungen, auf Grundlage von Standard­datenschutzklauseln oder mit anderen geeigneten Garantien gewährleistet sein. Ausnahmsweise können wir Personendaten in Staaten ohne angemessenen oder geeigneten Datenschutz exportieren, wenn dafür die besonderen datenschutz­rechtlichen Voraussetzungen erfüllt sind, beispielsweise die ausdrückliche Einwilligung der betroffenen Personen oder ein unmittelbarer Zusammenhang mit dem Abschluss oder der Abwicklung eines Vertrages. Wir geben betroffenen Personen auf Nachfrage gerne Auskunft über allfällige Garantien oder liefern eine Kopie von Garantien.</p>
                            
                            <h2 id="rechte">5. Rechte von betroffenen Personen</h2>
                            
                            <h3 id="ansprueche">5.1 Datenschutzrechtliche Ansprüche</h3>
                            
                            <p>Wir gewähren betroffenen Personen sämtliche Ansprüche gemäss dem anwendbaren Datenschutzrecht. Betroffene Personen verfügen insbesondere über folgende Rechte:</p>
                            
                            <ul>
                            <li><strong>Auskunft:</strong> Betroffene Personen können Auskunft verlangen, ob wir Personendaten über sie bearbeiten, und falls ja, um welche Personendaten es sich handelt. Betroffene Personen erhalten ferner jene Informationen, die erforderlich sind, um ihre datenschutzrechtlichen Ansprüche geltend zu machen und Transparenz zu gewährleisten. Dazu zählen die bearbeiteten Personendaten als solche, aber unter anderem auch Angaben zum Bearbeitungszweck, zur Dauer der Aufbewahrung, zu einer allfälligen Bekanntgabe bzw. einem allfälligen Export von Daten in andere Staaten und zur Herkunft der Personendaten.</li>
                            <li><strong>Berichtigung und Einschränkung:</strong> Betroffene Personen können unrichtige Personendaten berichtigen, unvollständige Daten vervollständigen und die Bearbeitung ihrer Daten einschränken lassen.</li>
                            <li><strong>Löschung und Widerspruch:</strong> Betroffene Personen können Personendaten löschen lassen («Recht auf Vergessen») und der Bearbeitung ihrer Daten mit Wirkung für die Zukunft widersprechen.</li>
                            <li><strong>Datenherausgabe und Datenübertragung:</strong> Betroffene Personen können die Herausgabe von Personendaten oder die Übertragung ihrer Daten an einen anderen Verantwortlichen verlangen.</li>
                            </ul>
                            
                            <p>Wir können die Ausübung der Rechte von betroffenen Personen im rechtlich zulässigen Rahmen aufschieben, einschränken oder verweigern. Wir können betroffene Personen auf allenfalls zu erfüllende Voraussetzungen für die Ausübung ihrer datenschutzrechtlichen Ansprüche hinweisen. Wir können beispielsweise die Auskunft mit Verweis auf Geschäftsgeheimnisse oder den Schutz anderer Personen ganz oder teilweise verweigern. Wir können beispielsweise auch die Löschung von Personendaten mit Verweis auf gesetzliche Aufbewahrungspflichten ganz oder teilweise verweigern.</p>
                            
                            <p>Wir können für die Ausübung der Rechte <em>ausnahmsweise</em> Kosten vorsehen. Wir informieren betroffene Personen vorgängig über allfällige Kosten.</p>
                            
                            <p>Wir sind verpflichtet, betroffene Personen, die Auskunft verlangen oder andere Rechte geltend machen, mit angemessenen Massnahmen zu identifizieren. Betroffene Personen sind zur Mitwirkung verpflichtet.</p>
                            
                            <h3 id="beschwerderecht">5.2 Recht auf Beschwerde</h3>
                            
                            <p>Betroffene Personen haben das Recht, ihre datenschutzrechtlichen Ansprüche auf dem Rechtsweg durchzusetzen oder Beschwerde bei einer zuständigen Datenschutz-Aufsichtsbehörde zu erheben.</p>
                            
                            <p>Datenschutz-Aufsichtsbehörde für private Verantwortliche und Bundesorgane in der Schweiz ist der <a href="https://www.edoeb.admin.ch/" rel="nofollow noopener noreferrer" target="_blank">Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte</a>&nbsp;(EDÖB).</p>
                            
                            <h2 id="sicherheit">6. Datensicherheit</h2>
                            
                            <p>Wir treffen geeignete technische und organisatorische Massnahmen, um eine dem jeweiligen Risiko angemessene Datensicherheit zu gewährleisten. Wir können aber keine absolute Datensicherheit gewährleisten.</p>
                            
                            <p>Der Zugriff auf unsere Website erfolgt mittels Transportverschlüsselung (SSL&nbsp;/ TLS, insbesondere mit dem Hypertext Transfer Protocol Secure, abgekürzt HTTPS). Die meisten Browser kennzeichnen Transportverschlüsselung mit einem Vorhängeschloss in der Adressleiste.</p>
                            
                            <p>Unsere digitale Kommunikation unterliegt&nbsp;– wie <em>grundsätzlich</em> jede digitale Kommunikation&nbsp;– der Massenüberwachung ohne Anlass und Verdacht sowie sonstiger Überwachung durch Sicherheitsbehörden in der Schweiz, im übrigen Europa, in den Vereinigten Staaten von Amerika (USA) und in anderen Staaten. Wir können keinen direkten Einfluss auf die entsprechende Bearbeitung von Personendaten durch Geheimdienste, Polizeistellen und andere Sicherheitsbehörden nehmen.</p>
                            
                            <h2 id="website">7. Nutzung der Website</h2>
                            
                            <h3 id="cookies">7.1 Cookies</h3>
                            
                            <p>Wir können Cookies verwenden. Bei Cookies&nbsp;– eigenen Cookies (First-Party-Cookies) als auch Cookies von Dritten, deren Dienste wir nutzen (Third-Party-Cookies)&nbsp;– handelt es sich um Daten, die im Browser gespeichert werden. Solche gespeicherten Daten müssen nicht auf traditionelle Cookies in Textform beschränkt sein.</p>
                            
                            <p>Cookies können im Browser temporär als «Session Cookies» oder für einen bestimmten Zeitraum als sogenannte permanente Cookies gespeichert werden. «Session Cookies» werden automatisch gelöscht, wenn der Browser geschlossen wird. Permanente Cookies haben eine bestimmte Speicherdauer. Cookies ermöglichen insbesondere, einen Browser beim nächsten Besuch unserer Website wiederzuerkennen und dadurch beispielsweise die Reichweite unserer Website zu messen. Permanente Cookies können aber beispielsweise auch für Online-Marketing verwendet werden.</p>
                            
                            <p>Cookies können in den Browser-Einstellungen jederzeit ganz oder teilweise deaktiviert sowie gelöscht werden. Ohne Cookies steht unsere Website allenfalls nicht mehr in vollem Umfang zur Verfügung. Wir ersuchen&nbsp;– mindestens sofern und soweit erforderlich&nbsp;– aktiv um die ausdrückliche Einwilligung in die Verwendung von Cookies.</p>
                            
                            <h3 id="logdateien">7.2 Server-Logdateien</h3>
                            
                            <p>Wir können für jeden Zugriff auf unsere Website nachfolgende Angaben erfassen, sofern diese von Ihrem Browser an unsere Server-Infrastruktur übermittelt werden oder von unserem Webserver ermittelt werden können: Datum und Zeit einschliesslich Zeitzone, <a href="https://de.wikipedia.org/wiki/IP-Adresse" rel="nofollow noopener noreferrer" target="_blank">IP-Adresse</a>, Zugriffsstatus (HTTP-Statuscode), Betriebssystem einschliesslich Benutzeroberfläche und Version, Browser einschliesslich Sprache und Version, aufgerufene einzelne Unter-Seite unserer Website einschliesslich übertragener Datenmenge, zuletzt im gleichen Browser-Fenster aufgerufene Webseite (Referer bzw. Referrer).</p>
                            
                            <p>Wir speichern solche Angaben, die auch Personendaten darstellen können, in Server-Logdateien. Die Angaben sind erforderlich, um unsere Website dauerhaft, nutzerfreundlich und zuverlässig bereitstellen sowie um die Datensicherheit und damit insbesondere den Schutz von Personendaten sicherstellen zu können – auch durch Dritte oder mit Hilfe von Dritten.</p>
                            
                            <h3 id="zaehlpixel">7.3 Zählpixel</h3>
                            
                            <p>Wir können Zählpixel auf unserer Website verwenden. Zählpixel werden auch als Web-Beacons bezeichnet. Bei Zählpixeln&nbsp;– auch von Dritten, deren Dienste wir nutzen&nbsp;– handelt es sich um kleine, üblicherweise nicht sichtbare Bilder, die beim Besuch unserer Website automatisch abgerufen werden. Mit Zählpixeln können die gleichen Angaben wie in Server-Logdateien erfasst werden.</p>
                            
                            <h2 id="mitteilungen">8. Benachrichtigungen und Mitteilungen</h2>
                            
                            <p>Wir versenden Benachrichtigungen und Mitteilungen per E-Mail und über andere Kommunikationskanäle wie beispielsweise Instant Messaging oder SMS.</p>
                            
                            <h3 id="mitteilungen-statistik">8.1 Erfolgs- und Reichweitenmessung</h3>
                            
                            <p>Benachrichtigungen und Mitteilungen können Weblinks oder Zählpixel enthalten, die erfassen, ob eine einzelne Mitteilung geöffnet wurde und welche Weblinks dabei angeklickt wurden. Solche Weblinks und Zählpixel können die Nutzung von Benachrichtigungen und Mitteilungen auch personenbezogen erfassen. Wir benötigen diese statistische Erfassung der Nutzung für die Erfolgs- und Reichweitenmessung, um Benachrichtigungen und Mitteilungen aufgrund der Bedürfnisse und Lesegewohnheiten der Empfängerinnen und Empfänger effektiv und nutzerfreundlich sowie dauerhaft, sicher und zuverlässig versenden zu können.</p>
                            
                            <h3 id="mitteilungen-einwilligung">8.2 Einwilligung und Widerspruch</h3>
                            
                            <p>Sie müssen <em>grundsätzlich</em> in die Verwendung Ihrer E-Mail-Adresse und Ihrer sonstigen Kontaktadressen ausdrücklich einwilligen, es sei denn, die Verwendung ist aus anderen rechtlichen Gründen zulässig. Für eine allfällige Einwilligung verwenden wir nach Möglichkeit das «Double Opt-in»-Verfahren, das heisst, Sie erhalten eine E-Mail mit einem Weblink, den Sie zur Bestätigung anklicken müssen, damit kein Missbrauch durch unberechtigte Dritte erfolgen kann. Wir können solche Einwilligungen einschliesslich <a href="https://de.wikipedia.org/wiki/IP-Adresse" rel="nofollow noopener noreferrer" target="_blank">IP-Adresse</a> sowie Datum und Zeit aus Beweis- und Sicherheitsgründen protokollieren.</p>
                            
                            <p>Sie können <em>grundsätzlich</em> dem Erhalt von Benachrichtigungen und Mitteilungen wie beispielsweise Newslettern jederzeit widersprechen. Mit einem solchen Widerspruch können Sie gleichzeitig der statistischen Erfassung der Nutzung für die Erfolgs- und Reichweitenmessung widersprechen. Vorbehalten bleiben erforderliche Benachrichtigungen und Mitteilungen im Zusammenhang mit unseren Aktivitäten und Tätigkeiten.</p>
                            
                            <h3 id="mitteilungen-dienstleister">8.3 Dienstleister für Benachrichtigungen und Mitteilungen</h3>
                            
                            <p>Wir versenden Benachrichtigungen und Mitteilungen mit Hilfe von spezialisierten Dienstleistern.</p>
                            
                            <h2 id="social-media">9. Social Media</h2>
                            
                            <p>Wir sind auf Social Media-Plattformen und anderen Online-Plattformen präsent, um mit interessierten Personen kommunizieren sowie über unsere Aktivitäten und Tätigkeiten informieren zu können. Im Zusammenhang mit solchen Plattformen können Personendaten auch ausserhalb der Schweiz bearbeitet werden.</p>
                            
                            <p>Es gelten jeweils auch die Allgemeinen Geschäftsbedingungen (AGB) und Nutzungsbedingungen sowie Datenschutzerklärungen und sonstigen Bestimmungen der einzelnen Betreiber solcher Plattformen. Diese Bestimmungen informieren insbesondere über die Rechte von betroffenen Personen direkt gegenüber der jeweiligen Plattform, wozu beispielsweise das Recht auf Auskunft zählt.</p>
                            
                            <h2 id="dienste">10. Dienste von Dritten</h2>
                            
                            <p>Wir nutzen Dienste von spezialisierten Dritten, um unsere Aktivitäten und Tätigkeiten dauerhaft, nutzerfreundlich, sicher und zuverlässig ausüben zu können. Mit solchen Diensten können wir unter anderem Funktionen und Inhalte in unsere Website einbetten. Bei einer solchen Einbettung erfassen die genutzten Dienste aus technisch zwingenden Gründen mindestens zeitweilig die <a href="https://de.wikipedia.org/wiki/IP-Adresse" rel="nofollow noopener noreferrer" target="_blank">IP-Adressen</a> der Nutzerinnen und Nutzer.</p>
                            
                            <p>Für erforderliche sicherheitsrelevante, statistische und technische Zwecke können Dritte, deren Dienste wir nutzen, Daten im Zusammenhang mit unseren Aktivitäten und Tätigkeiten aggregiert, anonymisiert oder pseudonymisiert bearbeiten. Es handelt sich beispielsweise um Leistungs- oder Nutzungsdaten, um den jeweiligen Dienst anbieten zu können.</p>
                            
                            <p>Wir nutzen insbesondere:</p>
                            
                            <ul>
                            <li><strong><a href="https://about.google/products/" rel="nofollow noopener noreferrer" target="_blank">Dienste von Google:</a></strong> Anbieterinnen: Google&nbsp;LLC (USA) / Google Ireland Limited (Irland) für Nutzerinnen und Nutzer im Europäischen Wirtschaftsraum (EWR) und in der Schweiz; Allgemeine Angaben zum Datenschutz: <a href="https://safety.google/intl/de/principles/" rel="nofollow noopener noreferrer" target="_blank">«Grundsätze zu Datenschutz und Sicherheit»</a>, <a href="https://policies.google.com/privacy?hl=de" rel="nofollow noopener noreferrer" target="_blank">Datenschutzerklärung</a>, <a href="https://privacy.google.com/intl/de/businesses/compliance/" rel="nofollow noopener noreferrer" target="_blank">«Google ist der Einhaltung der anwendbaren Datenschutzgesetze verpflichtet»</a>, <a href="https://policies.google.com/technologies/product-privacy?hl=de" rel="nofollow noopener noreferrer" target="_blank">«Leitfaden zum Datenschutz in Google-Produkten»</a>, <a href="https://policies.google.com/technologies/partner-sites?hl=de" rel="nofollow noopener noreferrer" target="_blank">«Wie wir Daten von Websites oder Apps verwenden, auf bzw. in denen unsere Dienste genutzt werden» (Angaben von Google)</a>, <a href="https://policies.google.com/technologies/cookies?hl=de" rel="nofollow noopener noreferrer" target="_blank">«Von Google verwendete Cookie-Arten und sonstige Technologien»</a>, <a href="https://adssettings.google.com/" rel="nofollow noopener noreferrer" target="_blank">«Personalisierte Werbung» (Aktivierung / Deaktivierung / Einstellungen)</a>.
                            
                            </li>
                            </ul>
                            
                            <h3 id="infrastruktur">10.1 Digitale Infrastruktur</h3>
                            
                            <p>Wir nutzen Dienste von spezialisierten Dritten, um benötigte digitale Infrastruktur im Zusammenhang mit unseren Aktivitäten und Tätigkeiten in Anspruch nehmen zu können. Dazu zählen beispielsweise Hosting- und Speicherdienste von ausgewählten Anbietern.</p>
                            
                            <p>Wir nutzen insbesondere:</p>
                            
                            <ul>
                            <li><strong><a href="https://aws.amazon.com/de/" rel="nofollow noopener noreferrer" target="_blank">Amazon Web Services (AWS):</a></strong> Speicherplatz und sonstige Infrastruktur (Datenstandort: Frankfurt, Deutschland); Anbieterin: Amazon Web Services Inc. (USA); Angaben zum Datenschutz: <a href="https://aws.amazon.com/de/privacy/" rel="nofollow noopener noreferrer" target="_blank">Datenschutzerklärung</a>, <a href="https://aws.amazon.com/de/compliance/data-privacy/" rel="nofollow noopener noreferrer" target="_blank">«Datenschutz-Zentrum»</a>, <a href="https://aws.amazon.com/de/compliance/data-privacy-faq/" rel="nofollow noopener noreferrer" target="_blank">«Häufig gestellte Fragen zum Datenschutz»</a>.
                            
                            </li>
                            </ul>
                            
                            <h3 id="medien">10.2 Digitale Audio- und Video-Inhalte</h3>
                            
                            <p>Wir nutzen Dienste von spezialisierten Dritten, um das direkte Abspielen von digitalen Audio- und Video-Inhalten wie beispielsweise Musik oder Podcasts zu ermöglichen.</p>
                            
                            <p>Wir nutzen insbesondere:</p>
                            
                            <ul>
                            <li><strong><a href="https://www.youtube.com/" rel="nofollow noopener noreferrer" target="_blank">YouTube:</a></strong> Video-Plattform; Anbieterin: Google; YouTube-spezifische Angaben: <a href="https://support.google.com/youtube/topic/2803240?hl=de" rel="nofollow noopener noreferrer" target="_blank">«Datenschutz- und Sicherheitscenter»</a>, <a href="https://support.google.com/youtube/answer/9315727?hl=de" rel="nofollow noopener noreferrer" target="_blank">«Meine Daten auf YouTube»</a>.
                            
                            </li>
                            </ul>
                            
                            <h3 id="schriftarten">10.3 Schriftarten</h3>
                            
                            <p>Wir nutzen Dienste von Dritten, um ausgewählte Schriftarten sowie Icons, Logos und Symbole in unsere Website einbetten zu können.</p>
                            
                            <p>Wir nutzen insbesondere:</p>
                            
                            <ul>
                            <li><strong><a href="https://fonts.google.com" rel="nofollow noopener noreferrer" target="_blank">Google Fonts:</a></strong> Schriftarten; Anbieterin: Google; Google Fonts-spezifische Angaben: <a href="https://fonts.googleblog.com/2022/11/your-privacy-and-google-fonts.html" rel="nofollow noopener noreferrer" target="_blank">«Datenschutz und Google Fonts» («Privacy and Google Fonts»)</a>, <a href="https://developers.google.com/fonts/faq/privacy?hl=de" rel="nofollow noopener noreferrer" target="_blank">«Datenschutz und Datenerfassung»</a>.
                            
                            </li>
                            </ul>
                            
                            <h3 id="zahlungen">10.4 Zahlungen</h3>
                            
                            <p>Wir nutzen spezialisierte Dienstleister, um Zahlungen unserer Kundinnen und Kunden sicher und zuverlässig abwickeln zu können. Für die Abwicklung von Zahlungen gelten ergänzend die Rechtstexte der einzelnen Dienstleister wie Allgemeine Geschäftsbedingungen (AGB) oder Datenschutzerklärungen.</p>
                            
                            <p>Wir nutzen insbesondere:</p>
                            
                            <ul>
                            <li><strong><a href="https://stripe.com/de-ch" rel="nofollow noopener noreferrer" target="_blank">Stripe:</a></strong> Abwicklung von Zahlungen; Anbieterinnen: Stripe&nbsp;Inc. (USA)&nbsp;/ Stripe Payments Europe Limited (SPEL, Irland) für Nutzerinnen und Nutzer im Europäischen Wirtschaftsraum&nbsp;(EWR) und in der Schweiz sowie teilweise in Grossbritannien&nbsp;/ Stripe Payments UK Limited (Grossbritannien) und Stripe Capital Europe Limited (Irland) teilweise für Nutzerinnen und Nutzer in Grossbritannien; Angaben zum Datenschutz: <a href="https://stripe.com/de-ch/legal/privacy-center" rel="nofollow noopener noreferrer" target="_blank">«Stripe Datenschutz Center» («Stripe Privacy Center»)</a>, <a href="https://stripe.com/de-ch/privacy" rel="nofollow noopener noreferrer" target="_blank">Datenschutzerklärung</a>, <a href="https://stripe.com/de-ch/legal/cookies-policy" rel="nofollow noopener noreferrer" target="_blank">Cookie-Richtlinie</a>.
                            
                            </li>
                            </ul>
                            
                            <h2 id="schlussbestimmungen">11. Schlussbestimmungen</h2>
                            
                            <p>Wir können diese Datenschutzerklärung jederzeit anpassen und ergänzen. Wir werden über solche Anpassungen und Ergänzungen in geeigneter Form informieren, insbesondere durch Veröffentlichung der jeweils aktuellen Datenschutzerklärung auf unserer Website.</p>
                            
                        </div>

                        <mat-checkbox matInput formControlName="userAcceptedDSE" color="primary">
                            Ja, ich habe die Datenschutzerklärung <br> gelesen und akzeptiere diese. 
                        </mat-checkbox>

                        <div style="text-align:center;margin-top:15px;">
                            <button mat-raised-button matStepperPrevious style="margin-right:10px;">Zurück</button>
                            <button mat-raised-button color="primary" matStepperNext [disabled]="!onboardingForm.get('userDSE').valid">Weiter</button>
                        </div>

                    </mat-step>

                    <mat-step [stepControl]="onboardingForm.get('userConfiguration')" formGroupName="userConfiguration">

                        <ng-template matStepLabel>Passwort setzen</ng-template>
            
                        <div *ngIf="onboardingDone">
                            <h3>Sie sind jetzt startklar</h3>
                            <p style="text-align:center;"><a href="/login">Login</a></p>
                        </div>
            
                        <div class="form-group row">
            
                            <div class="col-md-12">
                                <p>Setzen Sie sich ein Passwort für ihren Account</p>
                            </div>
            
                            <div class="col-md-12">
                                <mat-form-field style="width:100%;" class="example-full-width">
                                  <input type="password" matInput placeholder="Passwort eingeben" formControlName="userPassword" [errorStateMatcher]="confirmValidParentMatcher"
                                  autocomplete="off">
                                </mat-form-field>
                                <mat-error *ngIf="onboardingForm.get('userConfiguration.userPassword').hasError('required') && onboardingForm.get('userConfiguration.userPassword').touched" >Bitte ein Passwort eingeben</mat-error>
                                <mat-error *ngIf="onboardingForm.get('userConfiguration.userPassword').hasError('pattern')">
                                    Das Passwort muss mindestens 8 Zeichen haben und folgende Eigenschaften erfüllen: Grossbuchstabe, Kleinbuchstabe, Zahl und eines dieser Sonderzeichen ~!@#$%^&*+-/.,\{{ '{' }}{{ '}' }}}[]();:|?<>="'`
                                </mat-error>
                                <mat-error *ngIf="onboardingForm.hasError('passwordNotMatch')">Die Passwörter stimmen nicht überein</mat-error>
                            </div>
            
                            <div class="col-md-12">
                                <mat-form-field style="width:100%;" class="example-full-width">
                                  <input type="password" matInput placeholder="Passwort wiederholen" formControlName="userPasswordRepeat" [errorStateMatcher]="confirmValidParentMatcher"
                                  autocomplete="off">
                                </mat-form-field>
                                <mat-error *ngIf="onboardingForm.get('userConfiguration.userPasswordRepeat').hasError('required') && onboardingForm.get('userConfiguration.userPasswordRepeat').touched">Bitte das Passwort wiederholen</mat-error>
                                <mat-error *ngIf="onboardingForm.hasError('passwordNotMatch')">Die Passwörter stimmen nicht überein</mat-error>
                            </div>
            
                        </div>

                        <div *ngIf="!onboardingDone" style="text-align:center;margin-top:15px;">
                            <button mat-raised-button matStepperPrevious style="margin-right:10px;">Zurück</button>
                            <button mat-raised-button color="primary" matStepperNext (click)="onboard()" [disabled]="!onboardingForm.valid">Fertig stellen</button>
                        </div>

                    </mat-step>

                </mat-horizontal-stepper>

            </form>

        </div>
    </div>
</div>