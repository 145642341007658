import { Injectable } from '@angular/core';

// Import from library
import {
  DialogLayoutDisplay,
  ToastNotificationInitializer
} from '@costlydeveloper/ngx-awesome-popup';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  isLoading: boolean = false

  constructor() { }
  ngOnInit() {
    this.isLoading = false
  }
  /**
   * @ngdoc function
   * @param title Title for toast
   * @param message Message to be displayed in toast
   * @param type  SUCCESS | INFO | NONE | DANGER | WARNING
   */
  toast(title, message) {

    const newToastNotification = new ToastNotificationInitializer();
    newToastNotification.setTitle(title);
    newToastNotification.setMessage(message);

    // Choose layout color type
    newToastNotification.setConfig({
        LayoutType: DialogLayoutDisplay.SUCCESS // SUCCESS | INFO | NONE | DANGER | WARNING
    });

    // Simply open the toast
    newToastNotification.openToastNotification$();

  }

  toastError(title, message) {

    const newToastNotification = new ToastNotificationInitializer();
    newToastNotification.setTitle(title);
    newToastNotification.setMessage(message);

    // Choose layout color type
    newToastNotification.setConfig({
        LayoutType: DialogLayoutDisplay.DANGER // SUCCESS | INFO | NONE | DANGER | WARNING
    });

    // Simply open the toast
    newToastNotification.openToastNotification$();

  }

}
