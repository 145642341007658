
    <div class="limiter">
        <div class="container-login100" style="background-image: url('/assets/images/login.jpg');">
            <div class="wrap-login100 p-l-55 p-r-55  p-b-54 " >

                <div style="text-align:center;margin-top: 25px;">
                    <img src="/assets/images/logo.png" style="max-height:100px;"/>
                </div>

                <form [formGroup]="loginForm" >

                    <span class="login100-form-title">
                        Anmelden
                    </span>

                    <div *ngIf="loginReturn" [style.color]="loginReturn.color" style="text-align:center;margin-top:10px;margin-bottom:10px;">
                        {{ loginReturn.message }}
                    </div>

                    <mat-form-field style="width:100%;" class="example-full-width">
                        <input matInput placeholder="E-Mail" formControlName="email"
                        autocomplete="off">
                        <mat-error *ngIf="loginForm.get('email').hasError('required')">Bitte eine E-Mail angeben</mat-error>
                        <mat-error *ngIf="loginForm.get('email').hasError('email')">
                            Bitte eine gültige Email Adresse angeben
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field style="width:100%;" class="example-full-width">
                        <input matInput type="password" [type]="hide ? 'password' : 'text'" placeholder="Passwort" formControlName="password"
                        autocomplete="off">
                        <mat-icon style="cursor:pointer;" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-hint *ngIf="!loginForm.get('password').value">Geben Sie Ihr Passwort ein</mat-hint>
                        <mat-error>Bitte ein Passwort eingeben</mat-error>
                    </mat-form-field>

                    
                    <div style="text-align:center;margin-top:15px;">
                        <button mat-raised-button style="background: #2CA5D8;border-color: #2CA5D8;color:white;" (click)="attemptLogin()" [disabled]="loginForm.invalid" >Login</button>
                    </div>
                
                </form>

                <div style="text-align:center;margin-top:30px;">
                    <a [routerLink]="'/password-reset'">Passwort vergessen?</a>    
                </div>

            </div>

        </div>
    </div>
    
