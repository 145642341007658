import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormControl } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loginReturn: any;
  hide: boolean = true

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private cookieService: CookieService,
    private router: Router,
    public authService: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    this.routerCheck();

    if(this.cookieService.get('ebt_asgood_api_session') != this.cookieService.get('XSRF-TOKEN')) {
      this.cookieService.delete('ebt_asgood_api_session')
      this.loginService.token = ''
      this.cookieService.delete('XSRF-TOKEN')
    }

    this.loginForm = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.email]),
      'password': new FormControl('', Validators.required),
    });

  }

  attemptLogin() {
    this.loginService.setToken().subscribe(data => {
      this.loginService.token = this.cookieService.get('XSRF-TOKEN')
      this.loginService.attemptLogin(this.loginForm.value).subscribe( data => {
    
        this.loginReturn = data
        this.loginService.setLoggedIn()
  
        this.routerCheck();
  
      })
    })
  }

  routerCheck() {

    if(this.loginService.getLogonStatus()) {

      // Login Worked, move along
      var redirectUrl = this.route.snapshot.queryParamMap.get('return')
      if(redirectUrl) {
        this.router.navigate([redirectUrl])
        //this.authService.redirectUrl = null
      } else {
        this.router.navigate(["/main/edited-checklists"]);
      }

    }

  }

}
