
    <div class="limiter">
        <div class="container-login100" style="background-image: url('/assets/images/login.jpg');">
            <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54" >

                <h1 style="text-align:center;">AS GOoD</h1>

                <form [formGroup]="registerForm">

                    <mat-horizontal-stepper linear #stepper>

                        <mat-step [stepControl]="registerForm.get('companyDetails')" formGroupName="companyDetails">
                    
                            <ng-template matStepLabel>Firma erstellen</ng-template>

                            <span class="login100-form-title">
                                Erstelle deine Firma
                            </span>

                            <mat-form-field style="width:100%;" class="example-full-width">
                                <input matInput placeholder="Firmenname" formControlName="companyName"
                                autocomplete="off">
                                <mat-error>Bitte einen Firmennamen angeben</mat-error>
                            </mat-form-field>

                            <mat-form-field style="width:100%;" class="example-full-width">
                                <input matInput placeholder="Firmenadrese" formControlName="companyAddress"
                                autocomplete="off">
                                <mat-error>Bitte einen Firmenadresse eingeben</mat-error>
                            </mat-form-field>

                            <mat-form-field style="width:100%;" class="example-full-width">
                                <input matInput placeholder="Postleitzahl" formControlName="companyZip" type="number"
                                autocomplete="off">
                                <mat-error *ngIf="registerForm.get('companyDetails.companyZip').hasError('required')">
                                    Bitte eine Postleitzahl für die Firmenadresse angeben
                                </mat-error>
                                <mat-error *ngIf="registerForm.get('companyDetails.companyZip').hasError('pattern')">
                                    Bitte eine in der Schweiz gültige Postleitzahl angeben
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field style="width:100%;" class="example-full-width">
                                <input matInput placeholder="Firmenort" formControlName="companyLocation"
                                autocomplete="off">
                                <mat-error>Bitte einen Firmenort angeben</mat-error>
                            </mat-form-field>
                            
                            <div style="text-align:center;margin-top:15px;">
                                <button mat-raised-button color="primary" matStepperNext [disabled]="!registerForm.get('companyDetails').valid">Weiter</button>
                            </div>

                        </mat-step>    
                    
                        <mat-step [stepControl]="registerForm.get('userDetails')" formGroupName="userDetails">
                    
                            <ng-template matStepLabel>SIBE erfassen</ng-template>

                            <span class="login100-form-title">
                                SIBE erfassen
                             </span>
 
                            <mat-form-field style="width:100%;" class="example-full-width">
                                <input matInput placeholder="E-Mail Adresse SIBE" formControlName="userEmail"
                                autocomplete="email">
                                <mat-error *ngIf="registerForm.get('userDetails.userEmail').hasError('required')">Bitte eine Email für den SIBE Account angeben</mat-error>
                                <mat-error *ngIf="registerForm.get('userDetails.userEmail').hasError('email')">
                                    Bitte eine gültige Email Adresse für den SIBE Account angeben
                                </mat-error>
                            </mat-form-field>
 
                            <mat-form-field style="width:100%;" class="example-full-width">
                                <input matInput placeholder="Vorname SIBE" formControlName="userSurname"
                                autocomplete="given-name">
                                <mat-error>Bitte einen Vornamen für den SIBE angeben</mat-error>
                            </mat-form-field>
 
                            <mat-form-field style="width:100%;" class="example-full-width">
                                <input matInput placeholder="Nachname SIBE" formControlName="userName"
                                autocomplete="family-name">
                                <mat-error>Bitte einen Nachnamen für den SIBE angeben</mat-error>
                            </mat-form-field>
 
                            <mat-form-field style="width:100%;" class="example-full-width">
                                <input matInput type="password" placeholder="Passwort" formControlName="userPassword" [errorStateMatcher]="confirmValidParentMatcher"
                                autocomplete="new-password">
                                <mat-error *ngIf="registerForm.get('userDetails.userPassword').hasError('required')">Bitte ein Passwort eingeben</mat-error>
                                <mat-error *ngIf="registerForm.get('userDetails.userPassword').hasError('pattern')">
                                    {{ allowedSpecialChars }}
                                </mat-error>
                                <mat-error *ngIf="registerForm.hasError('passwordNotMatch')">Die Passwörter stimmen nicht überein</mat-error>
                            </mat-form-field>

                            <mat-form-field style="width:100%;" class="example-full-width">
                                <input matInput type="password" placeholder="Passwort wiederholen" formControlName="userPasswordRepeat" [errorStateMatcher]="confirmValidParentMatcher"
                                autocomplete="new-password">
                                <mat-error *ngIf="registerForm.get('userDetails.userPasswordRepeat').hasError('required')">Bitte ein Passwort eingeben</mat-error>
                                <mat-error *ngIf="registerForm.hasError('passwordNotMatch')">Die Passwörter stimmen nicht überein</mat-error>
                            </mat-form-field>

                            <div style="text-align: center;margin-top:15px;">
                                <button mat-raised-button matStepperPrevious style="margin-right:10px;">Zurück</button>
                                <button mat-raised-button color="primary" matStepperNext [disabled]="!registerForm.get('userDetails').valid">Weiter</button>                            
                            </div>
                    
                        </mat-step>    
                    
                        <mat-step [stepControl]="registerForm.get('userAGB')" formGroupName="userAGB">
                    
                            <ng-template matStepLabel>AGBs</ng-template>

                             <div style="max-height: 300px;overflow-y: scroll;">
                                <h2>AS GOoD – Allgemeine Geschäftsbedingungen (AGB)</h2><br><br>

                                Stand: 19.01.2020<br><br>

                                AS GOoD ist eine Plattform, welche schweizerischen Unternehmen sowie anderen Nutzerinnen und Nutzern erleichtert, die Arbeitssicherheit und den Gesundheitsschutz zu gewährleisten (nachfolgend die «Plattform»). Anbieterin der Plattform ist die Enderle Beratung GmbH mit Sitz in Winterthur (nachfolgend die «Anbieterin»).
                                <br><br>
                                AS GOoD steht für ArbeitsSicherheit und Gesundheitsschutz Organisieren und Dokumentieren. Mit der Plattform können Unternehmen ihre Tätigkeiten betreffend Arbeitssicherheit und Gesundheitsschutz, die sie in ihrem Betrieb durchführen, dokumentieren und systematisieren. Mit der Plattformen können Unternehmen insbesondere ein Sicherheitssystem gemäss EKAS-Richtlinie 6508 umsetzen, eine Gefahrenermittlung durchführen und die getroffenen Massnahmen dokumentieren.
                                <br><br>
                                Diese Allgemeinen Geschäftsbedingungen (AGB) regeln die Rechte und Pflichten der Anbieterin sowie der Nutzerinnen und Nutzer der Plattform (nachfolgend gemeinsam die «Nutzer»). Für einzelne oder zusätzliche Funktionen und Leistungen können ergänzende oder weitere Bedingungen und sonstige rechtliche Dokumente gelten. Informationen zum Datenschutz finden sich in der Datenschutzerklärung.
                                <br><br>
                                1.	Funktionen und Leistungen von AS GOoD
                                <br><br>
                                1.1	Die Anbieterin betreibt die Plattform für die einzelnen Nutzer als Software-as-a-Service (SaaS)-Angebot. Die Anbieterin stellt den Nutzern den Zugang zur jeweils aktuellen Version der Plattform zur Verfügung.
                                <br><br>
                                1.2	Die Anbieterin ermöglicht den Nutzern, offizielle ASA-Checklisten und -Dokumente wie insbesondere Checklisten und Dokumente der EKAS, des SECO und der SUVA zu verwenden, womit für die betreffenden Themen der ASA-Beizug gewährleistet ist. In einem Betrieb gilt das erforderliche Fachwissen als vorhanden, wenn «von ASA entwickelte Unterlagen, beispielsweise Checklisten» umgesetzt werden (EKAS-Richtlinie 6508, Anhang 4 «Erforderliches Fachwissen»).
                                <br><br>
                                1.3	Die einzelnen Funktionen und Leistungen der Plattform sowie die damit verbundenen Kosten richten sich nach den vertraglichen Vereinbarungen zwischen der Anbieterin und einzelnen Nutzern sowie nach den Bedingungen, welche die Anbieterin im Rahmen der Plattform, auf ihrer Website oder anderweitig veröffentlicht hat. Es gibt keinen Anspruch, die Plattform nutzen zu dürfen.
                                <br><br>
                                1.4	Die Anbieterin betreibt die Infrastruktur für die Plattform, speichert die Daten der Nutzer im Zusammenhang mit der Plattform und leistet den Nutzern angemessene Unterstützung bei der Nutzung der Plattform. Die Anbieterin ist berechtigt, alle oder einzelne Leistungen im Zusammenhang mit der Plattform durch beauftragte oder anderweitig beigezogene Dritte erbringen zu lassen.
                                <br><br>
                                1.5	Die Anbieterin ist berechtigt, die Plattform insgesamt oder in Bezug auf einzelne Funktionen und Leistungen anzupassen und weiterzuentwickeln. Die Anbieterin informiert die Nutzer in geeigneter Form über massgebliche Anpassungen.
                                <br><br>
                                1.6	Die Anbieterin ist berechtigt, den Nutzern Mitteilungen im Zusammenhang mit der Plattform per E-Mail, Instant Messaging und über sonstige Kommunikationskanäle zu senden. Solche Mitteilungen können auch Werbung für Funktionen und Leistungen der Plattform enthalten. Die Nutzer können dem Empfang von Mitteilungen, die Werbung enthalten, jederzeit widersprechen («Opt-out»).
                                <br><br>
                                2.	Nutzung von AS GOoD
                                <br><br>
                                2.1	Die Anbieterin räumt den Nutzern während der jeweiligen Vertragsdauer ein nicht ausschliessliches, nicht übertragbares und nicht unterlizenzierbares Nutzungsrecht für die Plattform und sämtliche vereinbarten Funktionen, Inhalte und sonstigen Leistungen im Zusammenhang mit der Plattform für ausschliesslich eigene gewerbliche beziehungsweise unternehmerische Zwecke ein.
                                <br><br>
                                2.2	Das Nutzungsrecht ist auf die rechtskonforme und vereinbarungsgemässe Nutzung der Plattform durch die einzelnen Nutzer beschränkt. Die Nutzung der Plattform erfolgt in eigener Verantwortung der Nutzer. Bei Gefährdungen im Betrieb, welche nicht in ASA-Checklisten und -Dokumenten aufbereitet sind, muss der ASA-Beizug zusätzlich gewährleistet werden: «Der Arbeitgeber zieht Spezialisten der Arbeitssicherheit bei, wenn in seinem Betrieb besondere Gefährdungen […] auftreten und wenn in seinem Betrieb das erforderliche Fachwissen zur Gewährleistung der Arbeitssicherheit und des Gesundheitsschutzes nicht vorhanden ist.» (EKAS-Richtlinie 6508, Ziffer 2 (Beizug von Arbeitsärzten und anderen Spezialisten der Arbeitssicherheit).
                                <br><br>
                                2.3	Die Anbieterin stellt den Nutzern individuelle Nutzerkonten zur Verfügung oder ermöglicht bestimmten Nutzern, individuelle Nutzerkonten für Nutzer im gleichen Unternehmen selbst zu erstellen (nachfolgend die «Hauptnutzer»). Alle Nutzer sind verpflichtet, mit angemessenen technischen und organisatorischen Massnahmen eine missbräuchliche Nutzung zu verhindern und den Zugang zu ihren Nutzerkonten zu schützen. Hauptnutzer sind verantwortlich für die vereinbarungsgemässe Nutzung der Plattform im Rahmen von selbst erstellten individuellen Nutzerkonten.
                                <br><br>
                                2.4	Die Anbieterin speichert die Daten der Nutzer – einschliesslich Dokumente mit Sicherheits-Checklisten – während der jeweiligen Vertragsdauer. Die Daten gehören zum Rechtsbereich der einzelnen Nutzer, die ausschliesslich für die Speicherung und sonstige Bearbeitung solcher Daten verantwortlich sind. Die Datensicherung (Backup) obliegt den einzelnen Nutzern. Bei der Bearbeitung von Personendaten sind die Nutzer zu einer strikten Einhaltung des anwendbaren Datenschutzrechts verpflichtet.
                                <br><br>
                                2.5	Die Anbieterin behandelt sämtliche Informationen, die sie von Nutzern im Rahmen der Nutzung der Plattform erfährt, vertraulich und unterlässt die Weitergabe solcher Informationen an unberechtigte Dritte. Die Vertraulichkeitspflicht bleibt über die Vertragsdauer hinaus bestehen. Die Anbieterin trifft angemessene Massnahmen gegen Datenverlust und zur Gewährleistung der Datensicherheit. Sofern und soweit die Anbieterin nicht gesetzlich oder aus anderen Gründen zur Aufbewahrung von Daten der Nutzer verpflichtet ist, ist die Anbieterin berechtigt, solche Daten nach Vertragsende zu löschen.
                                <br><br>
                                2.6	Jegliche Nutzung der Plattform in rechtswidriger Art und Weise oder zu rechtswidrigen Zwecken ist untersagt. Es obliegt den Nutzern insbesondere zu gewährleisten, dass das anwendbare Datenschutz- und Urheberrecht eingehalten wird sowie keine Rechte Dritter verletzt werden.
                                <br><br>
                                2.7	Die Nutzer halten die Anbieterin für sämtliche Aufwendungen und Kosten im Zusammenhang mit jeglicher Haftung schadlos, die durch eine rechtswidrige Nutzung der Plattform entstehen. Die Nutzer stellen die Anbieterin weiter von sämtlichen Ansprüchen anderer Nutzer sowie allfälliger Dritter frei, sofern und soweit solche Ansprüche im Zusammenhang mit ihrer Nutzung der Plattform stehen.
                                <br><br>
                                2.8	Die Anbieterin ist jederzeit berechtigt, bei einer Verletzung dieser Nutzungsbedingungen oder von sonstigen vertraglichen Vereinbarungen sowie bei einem Verdacht auf eine missbräuchliche Nutzung, einzelnen Nutzern den Zugang zur Plattform unverzüglich zu sperren. Die Anbieterin kann betroffene Nutzer in solchen Fällen im eigenen Ermessen abmahnen, ist aber nicht dazu verpflichtet.
                                <br><br>
                                3.	Kosten und Zahlungsbedingungen
                                <br><br>

                                3.1	Die Nutzer verpflichten sich, jederzeit sämtlichen finanziellen Verpflichtungen gegenüber der Anbieterin fristgerecht und vollumfänglich nachzukommen. Die Anbieterin ist nicht verpflichtet, geleistete Zahlungen zurückzuerstatten. Sämtliche anfallenden Kosten sind per sofort geschuldet, sofern die Anbieterin keine andere Zahlungsfrist nennt. Die Anbieterin ist berechtigt, die Nutzung der Plattform von Akonto- oder Vorauszahlungen abhängig zu machen.
                                <br><br>
                                3.2	Die Nutzer geraten bei nicht fristgerechter Bezahlung ohne Zahlungserinnerung automatisch in Verzug. Die Anbieterin ist berechtigt, säumigen Nutzern den Zugang zur Plattform zu sperren. Die Anbieterin ist zustimmungsfrei berechtigt, Forderungen gegenüber Nutzern an Dritte abzutreten. Die Verrechnung von Forderungen durch Nutzer ist ohne die schriftliche Zustimmung der Anbieterin ausgeschlossen.
                                <br><br>
                                4.	Gewährleistung und Haftung
                                <br><br>
                                4.1	Die Anbieterin erbringt ihre Leistungen fachgerecht und sorgfältig. Die Anbieterin bemüht sich, dass der vertraglich vereinbarte Funktions- und Leistungsumfang der Plattform während der jeweiligen Vertragslaufzeit genutzt werden kann. Bei Mängeln, die Nutzer melden, bemüht sich die Anbieterin, solche Mängel innert angemessener Frist und mit angemessenen Massnahmen zu beheben.
                                <br><br>
                                4.2	Die Anbieterin ist berechtigt, den Zugang zur Plattform für Wartungsarbeiten zu unterbrechen. Die Anbieterin informiert die Nutzer nach Möglichkeit über geplante Wartungsfenster.
                                <br><br>
                                4.3	Die Anbieterin bemüht sich um eine hohe Verfügbarkeit und Zuverlässigkeit der Plattform, kann aber nicht gewährleisten, dass die Plattform fehlerfrei ist oder dauerhaft und jederzeit mit vollem Funktions- und Leistungsumfang verfügbar ist. Für Inhalte, die von Dritten oder von anderen Nutzern stammen, insbesondere für Sicherheits-Checklisten von Dritten, kann die Anbieterin keinerlei Gewähr übernehmen.
                                <br><br>
                                4.4	Jegliche Haftung der Anbieterin oder ihrer Hilfspersonen für direkte Sach- und Vermögensschäden sowie andere oder weitergehende Ansprüche und Schäden, insbesondere betreffend indirekte, mittelbare oder Folgeschäden, entgangener Gewinn, fehlerhafte oder unvollständige Informationen, entgangene Nutzung sowie Betriebs- oder Verdienstausfälle, ist ausdrücklich und vollumfänglich ausgeschlossen.
                                <br><br>
                                4.5	Die Beschränkung der Haftung gemäss diesen Allgemeinen Geschäftsbedingungen gilt unabhängig vom jeweiligen Rechtsgrund. Eine allenfalls weitergehende zwingende Haftung, insbesondere für grobe Fahrlässigkeit oder für Vorsatz sowie gemäss allenfalls anwendbarem Datenschutzrecht, bleibt vorbehalten.
                                <br><br>
                                5.	Vertragsdauer
                                <br><br>
                                5.1	Vertragsbeginn und Vertragsdauer richten sich nach den entsprechenden vertraglichen Vereinbarungen. Ansonsten beginnt der Vertrag mit dem gewährten Zugang zur Plattform und die Vertragslaufzeit ist unbeschränkt. Mit Vertragsende wird der Zugang zur Plattform gesperrt.
                                <br><br>
                                5.2	Der Vertrag kann sowohl von der Anbieterin als auch von Nutzern ordentlich mit einer Kündigungsfrist von zwei Monaten auf Monatsende gekündigt werden, sofern keine andere Kündigungsfrist vertraglich vereinbart wurde. Die Kündigung muss schriftlich oder in einer Form, die den Nachweis durch Text ermöglicht, erfolgen.
                                <br><br>
                                5.3	Der Vertrag kann von den Nutzern ausserordentlich mit einer Kündigungsfrist von 14 Tagen auf Monatsende aus wichtigem Grund gekündigt werden, wenn die Anbieterin den wichtigen Grund trotz Abmahnung nicht innerhalb einer angemessenen Frist von mindestens 30 Tagen behoben hat. Abmahnung und Kündigung müssen schriftlich erfolgen.
                                <br><br>
                                6.	Schlussbestimmungen
                                <br><br>
                                6.1	Der automatisierte Zugriff auf die Plattform, beispielsweise mit Bots, Skripten oder vergleichbaren Mitteln, ist untersagt. Die Anbieterin kann Ausnahmen wie beispielsweise die Nutzung von Programmierschnittstellen vorsehen.
                                <br><br>
                                6.2	Sollten einzelne Bestimmungen dieser Allgemeinen Geschäftsbedingungen unwirksam oder nichtig sein, so wird die Geltung der übrigen Bestimmungen dieser Allgemeinen Geschäftsbedingungen davon nicht berührt. Eine unwirksame oder nichtige Bestimmung ist durch eine rechtlich zulässige Bestimmung zu ersetzen, die dem ursprünglichen Zweck wirtschaftlich am nächsten kommt.
                                <br><br>
                                6.3	Die Anbieterin ist jederzeit berechtigt, diese Allgemeinen Geschäftsbedingungen ohne Angabe von Gründen anzupassen. Die Nutzer werden in geeigneter Form über massgebliche Änderungen dieser Allgemeinen Geschäftsbedingungen informiert.
                                <br><br>
                                6.4	Diese Allgemeinen Geschäftsbedingungen unterstehen ausschliesslich schweizerischem Recht mit ausschliesslichem Erfüllungsort und Gerichtsstand am Sitz der Anbieterin.

                             </div>
                            <br>
 
                            <mat-checkbox matInput formControlName="userAcceptedAGB" color="primary">
                                Ja, ich habe die allgemeinen Geschäftsbedingungen (AGBs) <br> gelesen und akzeptiere diese. 
                            </mat-checkbox>
 
                            <div style="text-align: center;margin-top:15px;">
                                <button mat-raised-button matStepperPrevious style="margin-right:10px;">Zurück</button>
                                <button mat-raised-button color="primary" [disabled]="!isUserAGBValid" matStepperNext>Weiter</button>
                            </div>
                    
                        </mat-step>    

                        <mat-step [stepControl]="registerForm.get('userDSE')" formGroupName="userDSE">
                    
                            <ng-template matStepLabel>Datenschutzerklärung</ng-template>

                             <div style="max-height: 300px;overflow-y: scroll;">
                                <h1 id="top">Datenschutzerklärung</h1> 

                                <p>Mit dieser Datenschutzerklärung informieren wir über die Bearbeitung von Personendaten im Zusammenhang mit unserer <strong>AS GOoD-Plattform (asgood.ch)</strong> und unserem sonstigen Online-Angebot.</p>

                                <p>Für einzelne oder zusätzliche Angebote und Leistungen können besondere, ergänzende oder weitere Datenschutzerklärungen sowie sonstige rechtliche Dokumente wie Allgemeine Geschäftsbedingungen (AGB), Nutzungsbedingungen oder Teilnahmebedingungen gelten.</p>

                                <h2 id="kontaktadressen">1. Kontaktadressen</h2>

                                <p>Verantwortung für das Online-Angebot:</p>

                                <p><strong id="verantwortlicher">Enderle Beratung GmbH</strong><br />
                                Jonas-Furrer-Strasse 83<br />
                                8400 Winterthur</p>

                                <p><a href="mailto:info@enderle-beratung-gmbh.ch">info@enderle-beratung-gmbh.ch</a></p>

                                <h2 id="bearbeitung">2. Bearbeitung von Personendaten</h2>

                                <h3 id="begriffe">2.1 Begriffe</h3>

                                <p><strong>Personendaten</strong> sind alle Angaben, die sich auf eine bestimmte oder bestimmbare Person beziehen. Eine <strong>betroffene Person</strong> ist eine Person, über die Personendaten bearbeitet werden. <strong>Bearbeiten</strong> umfasst <em>jeden</em> Umgang mit Personendaten, <em>unabhängig</em> von den angewandten Mitteln und Verfahren, insbesondere das Aufbewahren, Bekanntgeben, Beschaffen, Erheben, Löschen, Speichern, Verändern, Vernichten und Verwenden von Personendaten.</p>

                                <h3 id="rechtsgrundlagen">2.2 Rechtsgrundlagen</h3>

                                <p>Wir bearbeiten Personendaten im Einklang mit dem schweizerischen Datenschutzrecht wie insbesondere dem <a target="_blank" href="https://www.admin.ch/opc/de/classified-compilation/19920153/index.html" id="15192">Bundesgesetz über den Datenschutz</a> (DSG) und der <a target="_blank" href="https://www.admin.ch/opc/de/classified-compilation/19930159/index.html">Verordnung zum Bundesgesetz über den Datenschutz</a> (VDSG).</p>

                                <h3 id="art-umfang-zweck">2.3 Art, Umfang und Zweck</h3>

                                <p>Wir bearbeiten jene Personendaten, die <strong>erforderlich</strong> sind, um unser Online-Angebot dauerhaft, nutzerfreundlich, sicher und zuverlässig bereitstellen zu können. Solche Personendaten können in die Kategorien von Bestandes- und Kontaktdaten, Inhaltsdaten, Meta- beziehungsweise Randdaten sowie Nutzungsdaten, Standortdaten, Vertragsdaten und Zahlungsdaten fallen.</p>

                                <p>Wir bearbeiten Personendaten während jener <strong>Dauer</strong>, die für den jeweiligen Zweck beziehungsweise die jeweiligen Zwecke oder gesetzlich erforderlich ist. Personendaten, deren Bearbeitung nicht mehr erforderlich ist, werden anonymisiert oder gelöscht.</p>

                                <p>Wir bearbeiten Personendaten <em>grundsätzlich</em> nur nach Einwilligung der betroffenen Person, es sei denn, die Bearbeitung ist aus anderen rechtlichen Gründen zulässig, beispielsweise zur Erfüllung eines Vertrages mit der betroffenen Person und für entsprechende vorvertragliche Massnahmen, um unsere überwiegenden berechtigten Interessen zu wahren, weil die Bearbeitung aus den Umständen ersichtlich ist oder nach vorgängiger Information.</p>

                                <p>In diesem Rahmen bearbeiten wir insbesondere Angaben, die eine betroffene Person bei der Kontaktaufnahme – beispielsweise per Briefpost, E-Mail, Kontaktformular, Social Media oder Telefon – oder bei der Registrierung für ein Nutzerkonto <em>freiwillig und selbst</em> an uns übermittelt. Wir können solche Angaben beispielsweise in einem Adressbuch, in einem Customer Relationship Management-System (CRM-System) oder mit vergleichbaren Hilfsmitteln speichern. Sofern Sie Personendaten über Dritte an uns übermitteln, sind Sie verpflichtet, den Datenschutz gegenüber solchen Dritten zu gewährleisten sowie die Richtigkeit solcher Personendaten sicherzustellen.</p>

                                <p>Wir bearbeiten ausserdem Personendaten, die wir von Dritten erhalten, aus öffentlich zugänglichen Quellen beschaffen oder bei der Bereitstellung unseres Online-Angebotes erheben, sofern und soweit eine solche Bearbeitung aus rechtlichen Gründen zulässig ist.</p>

                                <h3 id="dritte">2.4 Bearbeitung von Personendaten durch Dritte, auch im Ausland</h3>

                                <p>Wir können Personendaten durch Dritte wie insbesondere Auftragsbearbeiter bearbeiten lassen oder gemeinsam mit Dritten sowie mit Hilfe von Dritten bearbeiten oder an Dritte übermitteln. Bei solchen Dritten handelt es sich insbesondere um Anbieter, deren Leistungen wir in Anspruch nehmen. Wir gewährleisten auch bei solchen Dritten einen angemessenen Datenschutz.</p>

                                <p>Solche Dritte befinden sich <em>grundsätzlich</em> in der Schweiz sowie im Europäischen Wirtschaftsraum (EWR) einschliesslich Europäischer Union (EU) und Fürstentum Liechtenstein. Solche Dritte können sich aber auch in sonstigen Staaten auf der Erde und anderswo im Universum befinden, sofern deren Datenschutzrecht nach <a target="_blank" href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html">Einschätzung des Eidgenössischen Datenschutz- und Öffentlichkeitsbeauftragten</a> (EDÖB) einen angemessenen Datenschutz gewährleistet, oder wenn aus anderen Gründen, wie beispielsweise durch eine entsprechende vertragliche Vereinbarung, insbesondere auf Grundlage von Standardvertragsklauseln, oder durch eine entsprechende Zertifizierung, ein angemessener Datenschutz gewährleistet ist. Bei Dritten in den Vereinigten Staaten von Amerika (USA) kann die Zertifizierung gemäss dem Privacy Shield einen angemessenen Datenschutz gewährleisten. Ausnahmsweise kann sich ein solcher Dritter in einem Land ohne angemessenen Datenschutz befinden, sofern dafür die datenschutzrechtlichen Voraussetzungen wie beispielsweise die ausdrückliche Einwilligung der betroffenen Person erfüllt werden.</p>

                                <h2 id="rechte">3. Rechte von betroffenen Personen</h2>

                                <p>Betroffene Personen, deren Personendaten wir bearbeiten, verfügen über die Rechte gemäss schweizerischem Datenschutzrecht. Dazu zählen das Recht auf Auskunft sowie das Recht auf Berichtigung, Löschung oder Sperrung der bearbeiteten Personendaten.</p>

                                <p>Betroffene Personen, deren Personendaten wir bearbeiten, verfügen über ein Beschwerderecht bei einer zuständigen Aufsichtsbehörde. Aufsichtsbehörde für den Datenschutz in der Schweiz ist der <a target="_blank" href="https://www.edoeb.admin.ch/">Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte</a> (EDÖB).</p>

                                <h2 id="sicherheit">4. Datensicherheit</h2>

                                <p>Wir treffen angemessene sowie geeignete technische und organisatorische Massnahmen, um den Datenschutz und insbesondere die Datensicherheit zu gewährleisten. Allerdings kann die Bearbeitung von Personendaten im Internet trotz solchen Massnahmen immer Sicherheitslücken aufweisen. Wir können deshalb keine absolute Datensicherheit gewährleisten.</p>

                                <p>Der Zugriff auf unser Online-Angebot erfolgt mittels Transportverschlüsselung (SSL / TLS mit HTTPS).</p>

                                <h2 id="website">5. Nutzung der Website</h2>

                                <h3 id="cookies">5.1 Cookies</h3>

                                <p>Wir können Cookies für unsere Website verwenden. Bei Cookies – auch von Dritten, deren Dienste wir nutzen (Cookies von Dritten beziehungsweise Third-Party Cookies) – handelt es sich um Daten in Textform, die in Ihrem Browser gespeichert werden. Cookies können keine Programme ausführen oder Schadsoftware wie Trojaner und Viren übertragen.</p>

                                <p>Cookies können beim Besuch unserer Website in Ihrem Browser temporär als «Session Cookies» oder für einen bestimmten Zeitraum als sogenannt permanente Cookies gespeichert werden. «Session Cookies» werden automatisch gelöscht, wenn Sie Ihren Browser schliessen. Permanente Cookies ermöglichen insbesondere, Ihren Browser beim nächsten Besuch unserer Website wiederzuerkennen und dadurch beispielsweise die Reichweite unserer Website zu messen. Permanente Cookies können aber beispielsweise auch für Online-Marketing verwendet werden.</p>

                                <p>Sie können Cookies in Ihren Browser-Einstellungen jederzeit ganz oder teilweise deaktivieren sowie löschen. Ohne Cookies steht unser Online-Angebot allenfalls nicht mehr in vollem Umfang zur Verfügung. Wir ersuchen Sie – sofern und soweit erforderlich – um Ihre Einwilligung für die Verwendung von Cookies.</p>

                                <h3 id="logdateien">5.2 Server-Logdateien</h3>

                                <p>Wir können für jeden Zugriff auf unsere Website nachfolgende Angaben erfassen, sofern diese von Ihrem Browser an unsere Server-Infrastruktur übermittelt werden oder von unserem Webserver ermittelt werden können: Datum und Zeit einschliesslich Zeitzone, Internet Protocol (IP)-Adresse, Zugriffsstatus (HTTP-Statuscode), Betriebssystem einschliesslich Benutzeroberfläche und Version, Browser einschliesslich Sprache und Version, aufgerufene einzelne Seite unserer Website einschliesslich übertragener Datenmenge, zuletzt im gleichen Browser-Fenster aufgerufene Webseite (Referer beziehungsweise Referrer).</p>

                                <p>Wir speichern solche Angaben, die auch Personendaten darstellen können, in Server-Logdateien. Die Angaben sind erforderlich, um unser Online-Angebot dauerhaft, nutzerfreundlich und zuverlässig bereitstellen sowie um die Datensicherheit und damit insbesondere den Schutz von Personendaten sicherstellen zu können – auch durch Dritte oder mit Hilfe von Dritten.</p>

                                <h3 id="zaehlpixel">5.3 Zählpixel</h3>

                                <p>Wir können Zählpixel auf unserer Website verwenden. Zählpixel werden auch als Web-Beacons bezeichnet. Bei Zählpixeln – auch von Dritten, deren Dienste wir nutzen – handelt es sich um kleine Bilder, die beim Besuch unserer Website abgerufen werden. Mit Zählpixeln können die gleichen Angaben wie in Server-Logdateien erfasst werden.</p>

                                <h2 id="newsletter">6. Benachrichtigungen und Mitteilungen</h2>

                                <p>Wir können Benachrichtigungen und Mitteilungen wie beispielsweise Newsletter per E-Mail und über andere Kommunikationskanäle wie beispielsweise Instant Messaging versenden.</p>

                                <h3 id="newsletter-messung">6.1 Erfolgsmessung- und Reichweitenmessung</h3>

                                <p>Benachrichtigungen und Mitteilungen können Weblinks oder Zählpixel enthalten, die erfassen, ob eine einzelne Mitteilung geöffnet wurde und welche Weblinks dabei angeklickt wurden. Solche Weblinks und Zählpixel können die Nutzung von Benachrichtigungen und Mitteilungen auch personenbezogen erfassen. Wir benötigen diese statistische Erfassung der Nutzung für die Erfolgs- und Reichweitenmessung, um Benachrichtigungen und Mitteilungen aufgrund der Bedürfnisse und Lesegewohnheiten der Empfängerinnen und Empfänger effektiv und nutzerfreundlich sowie dauerhaft, sicher und zuverlässig anbieten zu können.</p>

                                <h3 id="newsletter-einwilligung">6.2 Einwilligung und Widerspruch</h3>

                                <p>Sie müssen <em>grundsätzlich</em> in die Verwendung Ihrer E-Mail-Adresse und Ihrer sonstigen Kontaktadressen ausdrücklich einwilligen, es sei denn, die Verwendung ist aus anderen rechtlichen Gründen zulässig. Für eine allfällige Einwilligung für den Erhalt von E-Mails verwenden wir «Double Opt-in», das heisst Sie erhalten eine E-Mail mit einem Weblink, den Sie zur Bestätigung anklicken müssen, damit kein Missbrauch durch unberechtigte Dritte erfolgen kann. Wir können solche Einwilligungen einschliesslich Internet Protocol (IP)-Adresse sowie Datum und Zeit aus Beweis- und Sicherheitsgründen protokollieren.</p>

                                <p>Sie können sich <em>grundsätzlich</em> jederzeit von Benachrichtigungen und Mitteilungen wie beispielsweise Newslettern abmelden. Vorbehalten bleiben Benachrichtigungen und Mitteilungen, die für unser Online-Angebot zwingend erforderlich sind. Mit der Abmeldung können Sie insbesondere der statistischen Erfassung der Nutzung für die Erfolgs- und Reichweitenmessung, widersprechen.</p>

                                <h3 id="newsletter-dienste">6.3 Dienstleister für Benachrichtigungen und Mitteilungen</h3>

                                <p>Wir können Benachrichtigungen und Mitteilungen durch Dienstleister versenden lassen oder mit Hilfe von Dienstleistern versenden. Wir gewährleisten auch bei solchen Dienstleistern einen angemessenen Datenschutz.</p>

                                <h2 id="social-media">7. Social Media</h2>

                                <p>Wir sind auf Social Media-Plattformen und anderen Online-Plattformen präsent, um mit interessierten Personen kommunizieren und über unser Online-Angebot informieren zu können. Es gelten jeweils auch die Allgemeinen Geschäftsbedingungen (AGB), Datenschutzerklärungen und sonstigen Bestimmungen der einzelnen Betreiberinnen und Betreiber solcher Online-Plattformen.</p>

                                <h2 id="dienste">8. Dienste von Dritten</h2>

                                <p>Wir können Dienste von Dritten verwenden, um unser Online-Angebot dauerhaft, nutzerfreundlich, sicher und zuverlässig bereitzustellen. Solche Dienste dienen auch dazu, Inhalte in unser Online-Angebot einbetten zu können. Solche Dienste – beispielsweise Hosting- und Speicherdienste, Video-Dienste sowie Zahlungsdienste – benötigen Ihre Internet Protocol (IP)-Adresse, da solche Dienste die entsprechenden Inhalte ansonsten nicht übermitteln können. Solche Dienste können sich ausserhalb der Schweiz sowie des Europäischen Wirtschaftsraumes (EWR) einschliesslich Europäischer Union (EU) und Fürstentum Liechtenstein befinden, sofern ein angemessener Datenschutz gewährleistet ist.</p>

                                <p>Für ihre eigenen sicherheitsrelevanten, statistischen und technischen Zwecke können Dritte, deren Dienste wir nutzen, auch Daten im Zusammenhang mit unserem Online-Angebot sowie aus anderen Quellen aggregiert, anonymisiert oder pseudonymisiert – unter anderem mit Cookies, Logdateien und Zählpixeln – bearbeiten. Solche Daten werden nicht verwendet, um direkt an betroffene Personen im Zusammenhang mit unserem Online-Angebot zu gelangen.<p>

                                <h3 id="schriftarten">8.1 Schriftarten</h3>

                                <p>Wir verwenden <strong>Google Fonts</strong>, um ausgewählte Schriftarten in unsere Website einbetten zu können. Dabei kommen <a target="_blank" href="https://developers.google.com/fonts/faq#what_does_using_the_google_fonts_api_mean_for_the_privacy_of_my_users"><em>keine</em> Cookies</a> zum Einsatz. Es handelt sich um einen Dienst der amerikanischen Google LLC, der unabhängig von anderen Google-Diensten angeboten wird. Für Nutzerinnen und Nutzer im Europäischen Wirtschaftsraum (EWR) und in der Schweiz ist die irische <a target="_blank" href="https://www.blog.google/around-the-globe/google-europe/some-changes-our-service-model-europe/">Google Ireland Limited</a> verantwortlich. Weitere Angaben über Art, Umfang und Zweck der Datenbearbeitung finden sich in den <a target="_blank" href="https://safety.google/principles/?hl=de">Grundsätzen für Datenschutz und Sicherheit</a> sowie in der <a target="_blank" href="https://policies.google.com/privacy?hl=de">Datenschutzerklärung</a> von Google.</p>

                                <h3 id="zahlungen">8.2 Zahlungen</h3>

                                <p>Wir verwenden Zahlungsdienstleister, um Zahlungen unserer Kundinnen und Kunden sicher und zuverlässig abwickeln zu können. Wir verwenden nur Zahlungsdienstleister, bei denen ein angemessener Datenschutz gewährleistet ist. Für die Abwicklung gelten jeweils die Bedingungen der betreffenden Zahlungsdienstleister wie beispielsweise Allgemeine Geschäftsbedingungen (AGB) oder Datenschutzerklärungen.</p>

                                <p>Wir verwenden insbesondere <strong>Stripe</strong> für die Abwicklung von Zahlungen. Stripe ist ein Dienst der Stripe Payments Europe Ltd. in Irland. Angaben zu Art, Umfang und Zweck der Datenverarbeitung finden sich in den <a target="_blank" href="https://stripe.com/ch/privacy#translation-de">Datenschutzgrundsätzen von Stripe</a>.</p>

                                <h2 id="schlussbestimmungen">9. Schlussbestimmungen</h2>

                                <p>Wir können diese Datenschutzerklärung jederzeit anpassen und ergänzen. Wir werden über solche Anpassungen und Ergänzungen in geeigneter Form informieren, insbesondere durch Veröffentlichung der jeweils aktuellen Datenschutzerklärung auf unserer Website.</p>

                             </div>
                            <br>
                            <mat-checkbox matInput formControlName="userAcceptedDSE" color="primary">
                                Ja, ich habe die Datenschutzerklärung <br> gelesen und akzeptiere diese. 
                            </mat-checkbox>
 
                            <div style="text-align: center;margin-top:15px;">
                                <button mat-raised-button matStepperPrevious style="margin-right:10px;">Zurück</button>
                                <button (click)="register()" [disabled]="!isFormValid" mat-raised-button color="primary" style="margin-left:10px;" matStepperNext>Registrieren</button>
                            </div>
                    
                        </mat-step>
                        
                        <mat-step>

                            <ng-template matStepLabel>Abschluss</ng-template>
                            
                            <div *ngIf="registrationSuccessful">

                                <h1>Fast geschafft!</h1>
    
                                <p>
                                    Sie haben per Email einen Link erhalten. Der Link bringt Sie direkt zum Login und schliesst den Registrationsprozess ab.
                                </p>
    
                            </div>

                        </mat-step>

                    </mat-horizontal-stepper>

                </form>
                                 
            </div>
        </div>
    </div>
    
