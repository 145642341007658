import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormControl, ValidatorFn, AbstractControl, ValidationErrors, FormGroupDirective } from '@angular/forms';
import { RegisterService } from '../services/register.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { LoginService } from '../services/login.service';
import { CookieService } from 'ngx-cookie-service';

/*export function passwordValidator(password, confirmed) {
  return (group: FormGroup): {[key: string]: any} => {
    let userPassword = group.get(password).value;
    let userPasswordConfirmed = group.get(confirmed).value
    if (userPassword !== userPasswordConfirmed) {
       return {
          'passwordNotEqual': true
       };
    } else {
      return {
        'passwordNotEqual': null
     };
    }
 }
}*/

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})

export class RegisterComponent implements OnInit {

  allowedSpecialChars = `Das Passwort muss mindestens 8 Zeichen haben und folgende Eigenschaften erfüllen: Grossbuchstabe, Kleinbuchstabe, Zahl und eines dieser Sonderzeichen ~!@#$%^&*+-/.,\{}[]();:|?<>=\"\'\``
  registerForm: FormGroup;
  registrationSuccessful: boolean = false;

  confirmValidParentMatcher = new ConfirmValidParentMatcher();

  constructor(
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private loginService: LoginService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {

    this.createForm();

  }

  createForm() {

    this.registerForm = new FormGroup({
      'companyDetails': new FormGroup({
        'companyName': new FormControl('', Validators.required),
        'companyAddress': new FormControl('', Validators.required),
        'companyZip': new FormControl('', [Validators.required, Validators.pattern('[0-9]{4}')]),
        'companyLocation': new FormControl('', Validators.required),
      }),
      'userDetails': new FormGroup({
        'userEmail': new FormControl('', [Validators.required, Validators.email]),
        'userSurname': new FormControl('', Validators.required),
        'userName': new FormControl('', Validators.required),
        'userPassword': new FormControl('', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\"`\'\\<\\>\\=\\|\\-\\_@$!%*#?:&\\\\{}\\[\\]\\(\\);,+.\\/^~-])[A-Za-z0-9`\'\\"\\<\\>\\=\\\\|\\-\\_@$!%*#?:&\\\\{}\\[\\]\\(\\);,+.\\/^~-]{8,}$')]),
        'userPasswordRepeat': new FormControl('', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\"`\'\\<\\>\\=\\|\\-\\_@$!%*#?:&\\\\{}\\[\\]\\(\\);,+.\\/^~-])[A-Za-z0-9`\'\\"\\<\\>\\=\\\\|\\-\\_@$!%*#?:&\\\\{}\\[\\]\\(\\);,+.\\/^~-]{8,}$')])
      }),
      'userAGB': new FormGroup({
        'userAcceptedAGB': new FormControl('', Validators.requiredTrue),
      }),
      'userDSE': new FormGroup({
        'userAcceptedDSE': new FormControl('', Validators.requiredTrue),
      }),
    }, {validators: this.passwordMatch.bind(this)});

  }

  passwordMatch(formGroup : FormGroup) {

    var password = formGroup.controls["userDetails"].value.userPassword
    var repeatPassword = formGroup.controls["userDetails"].value.userPasswordRepeat

    return password === repeatPassword ? null : { passwordNotMatch: true };

  }

  get isUserAGBValid() {
    return this.registerForm.get('userAGB').valid;
  }

  get isFormValid() {
    return this.registerForm.valid
  }

  register() {

    this.loginService.setToken().subscribe(data => {
      this.loginService.token = this.cookieService.get('XSRF-TOKEN')
        this.registerService.registerCompany(this.registerForm.value).subscribe(data => {

          this.registrationSuccessful = true

        });
    })

  }

}

/**
 * Custom ErrorStateMatcher which returns true (error exists) when the parent form group is invalid and the control has been touched
 */
export class ConfirmValidParentMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return control.parent.invalid && control.touched;
  }
}


