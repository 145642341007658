import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AppService {

  // Basic URL for this endpoint
  private apiUrl = `${environment.apiUrl}`

  constructor(
    private http: HttpClient,
  ) { }

  resetPasswordAttempt(resetPasswordForm) {
    return(this.http.post(`${this.apiUrl}/forgot`, resetPasswordForm))
  }

  resetPassword(setPasswordForm) {
    return(this.http.post(`${this.apiUrl}/reset`, setPasswordForm))
  }

  getConfig() {
    return(this.http.get(`${this.apiUrl}/appConfig.php`))
  }
  
}
