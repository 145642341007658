import { HostListener, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '../../environments/environment'
import { resolve } from 'url';
import { ReplaySubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  // Basic URL for this endpoint
  private apiUrl = `${environment.apiUrl}/user`
  public token = ''

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
  ) { }

  setLoggedIn() {
    localStorage.setItem('isLoggedIn', '1')
  }

  setLoggedOff() {
    localStorage.removeItem('isLoggedIn')
  }

  getLogonStatus() {
    if(localStorage.getItem('isLoggedIn')) {
      return true
    } else {
      return false
    }
  }

  setToken() {
    return(this.http.get(`${environment.baseApiUrl}/sanctum/csrf-cookie`))
  }

  retrieveToken() {
    this.token = this.cookieService.get('XSRF-TOKEN')
  }

  attemptLogin(loginData) {
    return(this.http.post(`${environment.baseApiUrl}/api/v1/auth/login`, loginData, {withCredentials: true}));  
  }
}
