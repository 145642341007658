import { Component, OnInit } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup, Validators, NgForm, FormControl, ValidatorFn, AbstractControl, ValidationErrors, FormGroupDirective } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { ResponseService } from 'src/app/services/response.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css']
})
export class OnboardingComponent implements OnInit {

  onboardingForm: FormGroup;

  token: String;
  companyName: String;
  userName: String;
  userSurname: String;
  onboardingDone: Boolean = false;

  confirmValidParentMatcher = new ConfirmValidParentMatcher();

  constructor(
    public responseService: ResponseService,
    private mainService: MainService,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private cookieService: CookieService,
    private router: Router
  ) { }

  ngOnInit() {
    
    this.token = this.activatedRoute.snapshot.paramMap.get('token');

    this.mainService.onboardConfig(this.token).subscribe(data => {
      
      this.companyName = data['companyName'];
      this.userName = data['userName'];
      this.userSurname = data['userSurname'];

    });

    this.responseService.clearOut()

    this.onboardingForm = new FormGroup({
      'userAGB': new FormGroup({
        'userAcceptedAGB': new FormControl('', Validators.requiredTrue),
      }),
      'userDSE': new FormGroup({
        'userAcceptedDSE': new FormControl('', Validators.requiredTrue),
      }),
      'userConfiguration': new FormGroup ({
        'userPassword': new FormControl('', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\"`\'\\<\\>\\=\\|\\-\\_@$!%*#?:&\\\\{}\\[\\]\\(\\);,+.\\/^~-])[A-Za-z0-9`\'\\"\\<\\>\\=\\\\|\\-\\_@$!%*#?:&\\\\{}\\[\\]\\(\\);,+.\\/^~-]{8,}$')]),
        'userPasswordRepeat': new FormControl('', Validators.required),
      })
    }, {validators: this.passwordMatch.bind(this)});


  }

  onboard() {

    this.onboardingForm.value.token = this.token

    this.loginService.setToken().subscribe(data => {
      this.loginService.token = this.cookieService.get('XSRF-TOKEN')
        this.mainService.onboard(this.onboardingForm.value).subscribe((data: Response) => {
          this.onboardingDone = true
          this.router.navigateByUrl('/login')
        });
    })

  }

  passwordMatch(formGroup : FormGroup) {

    var password = formGroup.controls['userConfiguration'].value.userPassword
    var repeatPassword = formGroup.controls['userConfiguration'].value.userPasswordRepeat

    if(password === repeatPassword) {
      return null
    } else if(password.length > 0 && repeatPassword.length > 0) {
      return { passwordNotMatch: true }
    }

  }

}

/**
 * Custom ErrorStateMatcher which returns true (error exists) when the parent form group is invalid and the control has been touched
 */
export class ConfirmValidParentMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      return control.parent.invalid && control.touched;
  }
}


