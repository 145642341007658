<div style="width:100%;height:100%;background-image: url('/assets/images/login.jpg');">

    <div style="text-align:center;padding-top:25rem" class="container">

        <div class="row">

            <div style="text-align:cente;" class="col-md-12">
    
                <h1>Wartungsarbeiten</h1>
                <p>Die Applikation wird momentan gewartet und ist deshalb nicht verfügbar</p>
                <a href="" (click)="navigate($event)">Zurück zum Login</a>
    
            </div>
    
        </div>

    </div>

</div>
