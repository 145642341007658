import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  // Basic URL for this endpoint
  private apiUrl = `${environment.apiUrl}/company/stripe`

  constructor(
    private http: HttpClient,
  ) { }

  registerCompany(registerData) {
    return(this.http.post(`${this.apiUrl}/register.php`, registerData)); 
  }

  getIntent() {
    return(this.http.get(`${this.apiUrl}/setup_intent`)); 
  }

  storePaymentMethod(setupIntent) {
    return(this.http.post(`${this.apiUrl}/payment_method`, setupIntent)); 
  }

  getSubscriptions() {
    return(this.http.get(`${this.apiUrl}/subscription`)); 
  }

  terminateSubscription(subscriptionID) {
    return(this.http.post(`${this.apiUrl}/subscription/terminate`, {
      subscriptionID : subscriptionID
    }))
  }

}
