import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class MainService {

  // Basic URL for this endpoint
  private apiUrl = `${environment.apiUrl}/user`

  constructor(
    private http: HttpClient,
  ) { }

  readUserConfig() {
    return(this.http.get(`${this.apiUrl}/config`)); 
  }

  logoutUser() {
    return(this.http.post(`${environment.baseApiUrl}/api/v1/auth/logout`, {})); 
  }

  onboardConfig(token) {
    return(this.http.get(`${this.apiUrl}/onboard/`+token)) 
  }

  onboard(onboardData) {
    return(this.http.post(`${this.apiUrl}/onboard`, onboardData))
  }
  
}
