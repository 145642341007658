import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup, Validators, NgForm, FormControl, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { AppService } from '../services/app.service';
import { ResponseService } from '../services/response.service';
import { LoginService } from '../services/login.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  forgotPasswordForm: FormGroup

  constructor(
    private appService: AppService,
    public responseService: ResponseService,
    public loginService: LoginService,
    public cookieService: CookieService
  ) { }

  ngOnInit() {

    this.forgotPasswordForm = new FormGroup({
      'userEmail': new FormControl('', [Validators.required, Validators.email]),
    });

  }

  submit() {

    this.loginService.setToken().subscribe(data => {
      this.loginService.token = this.cookieService.get('XSRF-TOKEN')
        this.appService.resetPasswordAttempt(this.forgotPasswordForm.value).subscribe(data => {
          
        })
    })

  }

}
