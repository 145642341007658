<div class="limiter">
    <div class="container-login100" style="background-image: url('/assets/images/login.jpg');">
        <div style="text-align:center;" class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54" >

            <h1 style="text-align:center;"> <img src="/assets/images/logo.png" style="height:50px;max-height:100px;"/> Passwort zurücksetzen</h1>

            <form [formGroup]="setPasswordForm" novalidate autocomplete="off">

                <div *ngIf="responseService.returnObject">
                      <div [ngStyle] ="{'color': responseService.returnColor}" style="text-align:center;" [innerHTML]="responseService.returnObject"></div>
                </div>

                <div style="text-align:center;" class="form-group row">

                    <div class="col-md-12">
                        <mat-form-field style="width:40%;" class="example-full-width">
                            <input type="password" matInput placeholder="Neues Passwort eingeben" formControlName="userPassword"
                            autocomplete="off">
                          </mat-form-field>
                          <mat-error *ngIf="setPasswordForm.get('userPassword').hasError('required') && setPasswordForm.get('userPassword').touched">Bitte ein neues Passwort eingeben</mat-error>
                          <mat-error *ngIf="setPasswordForm.get('userPassword').hasError('pattern')">Das Passwort muss mindestens 8 Zeichen haben und folgende Eigenschaften erfüllen: Grossbuchstabe, Kleinbuchstabe, Zahl und eines dieser Sonderzeichen ~!@#$%^&*+-/.,\{{ '{' }}{{ '}' }}}[]();:|?<>="'`</mat-error>
                          <mat-error *ngIf="setPasswordForm.hasError('passwordNotMatch')">Die beiden Passwörter sind nicht gleich</mat-error>
                    </div>

                    <div class="col-md-12">
                        <mat-form-field style="width:40%;" class="example-full-width">
                            <input type="password" matInput placeholder="Neues Passwort eingeben" formControlName="userPasswordRepeat"
                            autocomplete="off">
                          </mat-form-field>
                          <mat-error *ngIf="setPasswordForm.get('userPasswordRepeat').hasError('required') && setPasswordForm.get('userPasswordRepeat').touched">Bitte das neue Passwort wiederholen</mat-error>
                          <mat-error *ngIf="setPasswordForm.get('userPasswordRepeat').hasError('pattern')">Das Passwort muss mindestens 8 Zeichen haben und folgende Eigenschaften erfüllen: Grossbuchstabe, Kleinbuchstabe, Zahl und eines dieser Sonderzeichen ~!@#$%^&*+-/.,\{{ '{' }}{{ '}' }}}[]();:|?<>="'`</mat-error>
                          <mat-error *ngIf="setPasswordForm.hasError('passwordNotMatch')">Die beiden Passwörter sind nicht gleich</mat-error>
                    </div>

                    <div class="col-md-12">
                        <button [disabled]="!setPasswordForm.valid" (click)="submit()"  mat-raised-button style="margin-top:15px;background: #2CA5D8;border-color: #2CA5D8;color:white;">Passwort zurücksetzen</button>  
                    </div>

                </div>

            </form>

        </div>
    </div>
</div>