import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { LoginService } from './login.service';
@Injectable()
export class AuthGuardService implements CanActivate {

  url: string

  constructor(public router: Router,
              public route: ActivatedRoute,
              public cookieService: CookieService,
              public loginService: LoginService,
              public authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      var url: string = state.url;
  
      return this.checkLogin(url);
    }
  
    checkLogin(url: string): boolean {
      if(this.loginService.getLogonStatus()) {
        return(true)
      } else {
        // Preserve the url that was called
        var url = (window.location.href).replace(environment.baseUrl,'')
        this.loginService.setLoggedOff()
        this.router.navigateByUrl(`/login?return=${url}`)
      }
    }
}