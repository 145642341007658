import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpEventType,
    HttpResponse,
  } from '@angular/common/http';
import { Injectable } from '@angular/core';
  import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { switchMap, catchError, filter, take, retry, tap } from 'rxjs/operators';
import { ToastService } from './toast.service';
import { LoginService } from '../services/login.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { identifierModuleUrl } from '@angular/compiler';
@Injectable()
export class httpCallInterceptor implements HttpInterceptor {

    requestCounter = 0

    constructor(
        public toastService: ToastService,
        public loginService: LoginService,
        public cookieService: CookieService,
        public router: Router
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.url.indexOf('csrf-cookie') !== -1) {
            var clonedRequest = req.clone({
                withCredentials: true
            });
            return next.handle(clonedRequest).pipe(
                tap((event: HttpResponse<any>) => { }),
                catchError((error: HttpErrorResponse) => {
                    if (error.status == 503) {
                        this.loginService.setLoggedOff()
                        this.router.navigateByUrl('/maintenance')
                    }
                    return throwError(error.message);
                })
            );
        }

        this.requestCounter = this.requestCounter + 1

        this.toastService.isLoading = true

        if(this.cookieService.get('XSRF-TOKEN')) {
            var clonedRequest = req.clone({

                headers: req.headers.set('X-XSRF-TOKEN', this.loginService.token),
            });
            this.loginService.retrieveToken()
        }

        if(clonedRequest) {
            var clonedRequest = clonedRequest.clone({
                withCredentials: true
            }); 
        } else {
            var clonedRequest = req.clone({
                withCredentials: true
            }); 
        }

        return next.handle(clonedRequest).pipe(
            tap((event: HttpResponse<any>) => {
                if(event.status === 200) {
                    this.requestCounter = this.requestCounter - 1
                    if (event instanceof HttpResponse && ["POST", "PATCH", "PUT", "DELETE"].indexOf(req.method) > -1) {
                        if(event.body) {
                            this.toastService.toast(event.status + " " + event.body.title, event.body.message);
                        } else {
                            this.toastService.toast(event.status + " " + event.body.title, 'Vorgang war erfolgreich');
                        }
                    }
                    if(this.requestCounter <= 0) {
                        this.toastService.isLoading = false
                    }
                }
            }),
            catchError((error: HttpErrorResponse)=> {
                if(error.error instanceof Blob) {
                    var message: '';
                    new Promise<any>((resolve, reject) => {
                        let reader = new FileReader();
                        reader.onload = (e: Event) => {
                            try {
                                const errmsg = JSON.parse((<any>e.target).result);
                                resolve(errmsg)
                            } catch (e) {
                                reject(error);
                            }
                        };
                        reader.onerror = (e) => {
                            reject(error);
                        };
                        reader.readAsText(error.error);
                    }).then(response => {
                        this.requestCounter = this.requestCounter - 1
                        this.toastService.toastError(error.name + " " + error.status, response.message)
                        if(this.requestCounter == 0) {
                          this.toastService.isLoading = false
                        }
                        if (error.status == 401 || error.status == 419) {
                            this.loginService.setLoggedOff()
                            this.router.navigateByUrl('/login')
                        }
                        return throwError(response.message);
                    });
                } else {
                    if(error.status == 503) {
                        this.loginService.setLoggedOff()
                        this.router.navigateByUrl('/maintenance')
                    }
                    this.requestCounter = this.requestCounter - 1

                    if (error.status == 422) {
                        Object.keys(error.error['errors']).forEach(key => {
                            this.toastService.toastError(error.name + " " + error.status, error.error['errors'][key][0]) 
                        })
                    } else {
                        this.toastService.toastError(error.name + " " + error.status, error.error.message)  
                    }

                    if(this.requestCounter == 0) {
                      this.toastService.isLoading = false
                    }
                    if (error.status == 401 || error.status == 419) {
                        this.loginService.setLoggedOff()
                        this.router.navigateByUrl('/login')
                    }
                    return throwError(error.message);
                }

            })
        );
    }
}

