import { Injectable, ɵbypassSanitizationTrustUrl } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {

  returnLoader: boolean;
  returnObject: any;
  returnData: any;
  returnColor: any;

  constructor() {}

  placeLoader(height, width) {

    this.returnObject = `<img src="/assets/loader/loader.gif" height=${height} width=${width} />`;

  }

  loadResponse(data) {

    // Check the Response Type
    if(data.status == "success") {

      // Determines the Request as a success

      // Prepare the Return

        // Check if any Data has been appended
        if(data.data) {

          this.returnLoader = false;
          this.returnData = data.data;     
          this.returnColor = data.color;
          this.returnObject = `<p>${data.message}</p>`;

        } else {

          this.returnLoader = false;
          this.returnColor = data.color;
          this.returnObject = `<p>${data.message}</p>`;

        }

    } else if(data.status == "danger") {

      // Determines the Request as a failure

      // Prepare the Return

        // Check if any Data has been appended
        if(data.data) {

          this.returnLoader = false;
          this.returnData = data.data;     
          this.returnColor = data.color;
          this.returnObject = `<p>${data.message}</p>`;

        } else {

          this.returnLoader = false;
          this.returnColor = data.color;
          this.returnObject = `<p>${data.message}</p>`;

        }

    }

  }

  clearOut() {
    this.returnColor = ""
    this.returnObject = ""
  }

}
