import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './services/authGuard';
import { BevoComponent } from './main/components/bevo/bevo.component';
import { MainComponent } from './main/main.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordResetSetComponent } from './password-reset-set/password-reset-set.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

const routes: Routes = [
  {
    path        : 'register',
    component: RegisterComponent,
  },
  {
    path        : 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path        : 'login',
    component: LoginComponent,
  },
  {
    path        : 'onboard/:token',
    component: OnboardingComponent,
  },
  {
    path        : 'password-reset',
    component: PasswordResetComponent,
  },
  {
    path        : 'password-reset/:token',
    component: PasswordResetSetComponent,
  },
  {
    path        : 'main',
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuardService],
  },
  {
    path        : '**',
    component: LoginComponent,
    canActivate: [AuthGuardService],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
