import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormGroup, Validators, NgForm, FormControl, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { AppService } from '../services/app.service';
import { ResponseService } from '../services/response.service';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-password-reset-set',
  templateUrl: './password-reset-set.component.html',
  styleUrls: ['./password-reset-set.component.css']
})
export class PasswordResetSetComponent implements OnInit {

  setPasswordForm: FormGroup
  token: String;

  constructor(
    private appService: AppService,
    public responseService: ResponseService,
    private activatedRoute: ActivatedRoute,
    public loginSerivce: LoginService,
    public cookieService: CookieService
  ) { }

  ngOnInit() {

    this.token = this.activatedRoute.snapshot.paramMap.get('token');

    this.setPasswordForm = new FormGroup({
      'userPassword': new FormControl('', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\"`\'\\<\\>\\=\\|\\-\\_@$!%*#?:&\\\\{}\\[\\]\\(\\);,+.\\/^~-])[A-Za-z0-9`\'\\"\\<\\>\\=\\\\|\\-\\_@$!%*#?:&\\\\{}\\[\\]\\(\\);,+.\\/^~-]{8,}$')]),
      'userPasswordRepeat': new FormControl('', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\"`\'\\<\\>\\=\\|\\-\\_@$!%*#?:&\\\\{}\\[\\]\\(\\);,+.\\/^~-])[A-Za-z0-9`\'\\"\\<\\>\\=\\\\|\\-\\_@$!%*#?:&\\\\{}\\[\\]\\(\\);,+.\\/^~-]{8,}$')]),
    }, {validators: this.passwordMatch.bind(this)});

  }

  submit() {

    this.setPasswordForm.value.token = this.token

    this.loginSerivce.setToken().subscribe(data => {
      this.loginSerivce.token = this.cookieService.get('XSRF-TOKEN')
        this.appService.resetPassword(this.setPasswordForm.value).subscribe(data => {
          
        })
    })

  }

  passwordMatch(formGroup : FormGroup) {

    var password = formGroup.controls.userPassword.value
    var repeatPassword = formGroup.controls.userPasswordRepeat.value

    return password === repeatPassword ? null : { passwordNotMatch: true };

  }

}
