import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegisterComponent } from './register/register.component';
import { LoginService } from './services/login.service';
import { RegisterService } from './services/register.service';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBar, MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';

import {MatDialogModule} from '@angular/material/dialog';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { httpCallInterceptor } from './services/httCallInterceptor';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuardService } from './services/authGuard';
import { CompanyPaymentDialogComponent } from './main/company-payment-dialog/company-payment-dialog.component';
import { StripeService } from './services/stripe.service';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { AppService } from './services/app.service';
import { PasswordResetSetComponent } from './password-reset-set/password-reset-set.component';
import { JobGroupService } from './services/jobgroup.service';
import { MaintenanceComponent } from './maintenance/maintenance.component';

// Import from library
import {
  NgxAwesomePopupModule,
  DialogConfigModule,
  ConfirmBoxConfigModule,
  ToastNotificationConfigModule
} from '@costlydeveloper/ngx-awesome-popup';
import { ToastService } from './services/toast.service';

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    OnboardingComponent,
    PasswordResetComponent,
    PasswordResetSetComponent,
    MaintenanceComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatStepperModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatIconModule,
    NgxAwesomePopupModule.forRoot({
      ColorList: {
             Primary  : '#ff9e00', // optional
             Secondary: '#989ea5', // optional
             Info     : '#2f8ee5', // optional
             Success  : '#25be46', // optional
             Warning  : '#ffc107', // optional
             Danger   : '#e46464', // optional
             Light    : '#fbfbfb', // optional
             Dark     : '#343a40'  // optional
            }
    }),
    DialogConfigModule.forRoot(), // Needed for instantiating dynamic components.
    ConfirmBoxConfigModule.forRoot(), // Needed for instantiating confirm boxes.
    ToastNotificationConfigModule.forRoot()
  ],
  providers: [
    ToastService,
    LoginService,
    RegisterService,
    AppService,
    StripeService,
    JobGroupService,
    FormBuilder,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: httpCallInterceptor,
      multi: true,
    },
    CookieService,
    AuthGuardService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
